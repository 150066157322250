import { useGetRevenue } from 'api/dashboardApi';
import _ from 'lodash';
import ColumnChart from '../ColumnChart';

function normalizeData(data) {
  return _.map(data, (value, key) => ({
    name: _.capitalize(key),
    value,
  }));
}

export default function RevenueBlock() {
  const { data, isLoading } = useGetRevenue({
    options: {
      select: (response) => normalizeData(response),
    },
  });

  if (isLoading) {
    return null;
  }

  return <ColumnChart data={data} title="Doanh thu" />;
}
