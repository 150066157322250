import { useState } from 'react';
import { useIntl } from 'react-intl';
import { calcIndexByOrder, removeFalsyKeys } from 'utils/common';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { StyledActions, StyledButton } from 'styles/overrides';
import { Table } from 'components/table';
import { useLocales, useDeleteLocale } from 'api/localeApi';
import { Notification } from 'components/Notification';
import { formatTime } from 'utils/timeUtils';
import { Avatar, Modal } from 'antd';
import { Publish } from 'components/common';
import { Link } from 'react-router-dom';
import { PATH_NAME_ACTION } from 'constants/routes';

export default function LocaleTable() {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  const { data, isLoading, isFetching, refetch } = useLocales({
    params: search,
  });
  const deleteMutation = useDeleteLocale();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => {
        return calcIndexByOrder({
          index,
          page: search.page,
          limit: search.page_size,
        });
      },
    },
    {
      title: intl.formatMessage({ id: 'views.locales.table.code' }),
      dataIndex: ['code'],
    },
    {
      title: intl.formatMessage({ id: 'views.locales.table.name' }),
      dataIndex: ['name'],
    },
    {
      title: intl.formatMessage({ id: 'common.table.avatar' }),
      dataIndex: 'file',
      render: (value) => <Avatar src={value?.path} size={32} />,
    },
    {
      title: intl.formatMessage({ id: 'common.table.order' }),
      dataIndex: ['order'],
    },
    {
      title: intl.formatMessage({ id: 'common.table.published' }),
      dataIndex: 'published',
      render: (value) => <Publish published={value} />,
    },
    {
      title: intl.formatMessage({ id: 'common.table.createdAt' }),
      dataIndex: 'created_at',
      render: (value) => formatTime(value),
    },
    {
      render: ({ id }) => (
        <Link to={`${id}`}>
          <StyledButton type="primary">
            {intl.formatMessage({ id: 'common.btn.seeDetails' })}
          </StyledButton>
        </Link>
      ),
    },
  ];

  const handleDelete = () => {
    if (selectedRowKeys.length <= 0) return;

    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.deleteTitle' }),
      okText: intl.formatMessage({ id: 'common.btn.confirm' }),
      onOk() {
        return deleteMutation.mutateAsync(selectedRowKeys, {
          onSuccess() {
            refetch();

            setSelectedRowKeys([]);
            Notification(
              'success',
              intl.formatMessage({ id: 'message.deleteSuccess' }),
            );
          },
          onError(error) {
            Notification(
              'error',
              error?.message ||
                intl.formatMessage({ id: 'message.commonError' }),
            );
          },
        });
      },
      onCancel() {},
    });
  };

  const handleChangeTable = ({ current: page, pageSize: limit }) => {
    setSearch(removeFalsyKeys({ ...search, page, page_size: limit }));
  };

  return (
    <>
      <StyledActions size={24}>
        <Link to={PATH_NAME_ACTION.CREATE}>
          <StyledButton type="primary">
            {intl.formatMessage({ id: 'common.btn.addNew' })}
          </StyledButton>
        </Link>

        <StyledButton
          onClick={handleDelete}
          disabled={selectedRowKeys.length === 0}
        >
          {intl.formatMessage({ id: 'common.btn.delete' })}
        </StyledButton>
      </StyledActions>

      <Table
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
          },
          preserveSelectedRowKeys: true,
        }}
        columns={columns}
        dataSource={data || []}
        loading={isLoading || isFetching || deleteMutation.isLoading}
        pagination={false}
        onChange={handleChangeTable}
      />
    </>
  );
}
