import { useIntl } from 'react-intl';
import { Table } from 'components/table';
import { StyledButton } from 'styles/overrides';
import { Link } from 'react-router-dom';
import { useLabels } from 'api/labelApi';
import _ from 'lodash';

export default function SettingTable() {
  const intl = useIntl();
  const { data, isLoading, isFetching } = useLabels();

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'views.settings.table.key' }),
      dataIndex: 'key',
    },
    // {
    //   title: intl.formatMessage({ id: 'views.settings.table.content' }),
    //   dataIndex: 'value',
    //   render: (value) => {
    //     return _.map(value, (val, key) => {
    //       return (
    //         <p key={key}>
    //           {key}: {val}
    //         </p>
    //       );
    //     });
    //   },
    // },
    {
      render: ({ key }) => (
        <Link to={`${key}`}>
          <StyledButton type="primary">
            {intl.formatMessage({ id: 'common.btn.seeDetails' })}
          </StyledButton>
        </Link>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data || []}
        loading={isLoading || isFetching}
        pagination={false}
      />
    </>
  );
}
