import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useQrBox, useUpdateQrBox } from 'api/qrBoxApi';
import { Notification } from 'components/Notification';
import { ContentWrapper } from 'components/layout';
import { QRForm } from './components';

export default function ViewQRBox() {
  const intl = useIntl();
  const { id } = useParams();
  const { data, isLoading, isFetching } = useQrBox({ id });
  const updateMutation = useUpdateQrBox(id);

  const handleSubmit = (values) => {
    updateMutation.mutate(values, {
      onSuccess() {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.updateSuccess`,
          }),
        );
      },
      onError(error) {
        Notification(
          'error',
          error.message ?? intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  if (isLoading || isFetching || !data) {
    return null;
  }

  return (
    <ContentWrapper title="Chỉnh sửa">
      <QRForm
        onSubmit={handleSubmit}
        isLoading={updateMutation.isLoading}
        initialValues={data}
      />
    </ContentWrapper>
  );
}
