import { Notification } from 'components/Notification';
import { ContentWrapper } from 'components/layout';
import { useCreateFooter } from 'api/footerApi';
import { FooterForm } from './components';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { PATH_NAME } from 'constants/routes';

export default function AddFooter() {
  const intl = useIntl();
  const navigate = useNavigate();
  const createMutation = useCreateFooter();

  const handleSubmit = (values) => {
    createMutation.mutate(values, {
      onSuccess(response) {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.createSuccess`,
          }),
        );

        navigate(`/${PATH_NAME.FOOTERS}/${response.id}`);
      },
      onError(error) {
        Notification(
          'error',
          error.message ?? intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  return (
    <ContentWrapper title="Tạo mới">
      <FooterForm
        onSubmit={handleSubmit}
        isLoading={createMutation.isLoading}
      />
    </ContentWrapper>
  );
}
