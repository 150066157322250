import { useIntl } from 'react-intl';
import { Row, Col, Form, Switch } from 'antd';
import { VNMobilePhonePattern } from 'utils/validationUtils';
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledInput,
  StyledInputPassword,
} from 'styles/overrides';
import { BranchSelector, RoleSelector } from 'components/selectors';
import { ROLE } from 'constants/roles';

function normalizeInitialValues(data) {
  if (!data) {
    return { published: true };
  }
  const { code, email, phone, full_name, user_name, role, branch, published } =
    data;

  return {
    code,
    user_name,
    full_name,
    email,
    phone,
    role_code: role?.code,
    branch_id: branch?.id?.toString(),
    published: !!published,
  };
}

export default function UserForm({
  onSubmit,
  initialValues,
  isLoading = false,
}) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const isEdit = !!initialValues;

  const handleSubmit = (values) => {
    const data = {
      ...values,
      published: +values.published,
    };

    onSubmit?.(data);
  };

  return (
    <StyledForm
      layout="vertical"
      size="large"
      form={form}
      onFinish={handleSubmit}
      initialValues={normalizeInitialValues(initialValues)}
    >
      <Row gutter={[32, 0]}>
        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.users.form.label.fullName',
            })}
            name="full_name"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.users.form.label.role',
            })}
            name="role_code"
            rules={[{ required: true }]}
          >
            <RoleSelector disabled={isEdit} />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.role_code !== currentValues.role_code
            }
          >
            {({ getFieldValue }) => {
              const roleCode = getFieldValue('role_code');
              const isRequired =
                roleCode === ROLE.TECHNICIAN || roleCode === ROLE.RECEPTIONIST;

              return (
                <StyledFormItem
                  label={intl.formatMessage({
                    id: 'views.users.form.label.code',
                  })}
                  name="code"
                  rules={[{ required: isRequired }]}
                >
                  <StyledInput />
                </StyledFormItem>
              );
            }}
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.role_code !== currentValues.role_code
            }
          >
            {({ getFieldValue }) => {
              const roleCode = getFieldValue('role_code');
              const isRequired =
                roleCode === ROLE.TECHNICIAN || roleCode === ROLE.RECEPTIONIST;

              return (
                <StyledFormItem
                  label={intl.formatMessage({
                    id: 'views.users.form.label.branch',
                  })}
                  name="branch_id"
                  rules={[{ required: isRequired }]}
                >
                  <BranchSelector allowClear />
                </StyledFormItem>
              );
            }}
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.role_code !== currentValues.role_code
            }
          >
            {({ getFieldValue }) => {
              const roleCode = getFieldValue('role_code');
              const isRequired =
                roleCode === ROLE.ADMIN ||
                roleCode === ROLE.EDITOR ||
                roleCode === ROLE.RECEPTIONIST;

              return (
                <StyledFormItem
                  label={intl.formatMessage({
                    id: 'views.users.form.label.name',
                  })}
                  name="user_name"
                  rules={[{ required: isRequired }]}
                >
                  <StyledInput />
                </StyledFormItem>
              );
            }}
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.users.form.label.email',
            })}
            name="email"
            rules={[
              {
                type: 'email',
                message: intl.formatMessage({ id: 'validate.invalidEmail' }),
              },
              { required: true },
            ]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.users.form.label.phone',
            })}
            name="phone"
            rules={[
              {
                pattern: VNMobilePhonePattern,
                message: intl.formatMessage({
                  id: 'validate.invalidPhoneNumber',
                }),
              },
            ]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.role_code !== currentValues.role_code
            }
          >
            {({ getFieldValue }) => {
              const roleCode = getFieldValue('role_code');
              const isRequired =
                roleCode === ROLE.ADMIN ||
                roleCode === ROLE.EDITOR ||
                roleCode === ROLE.RECEPTIONIST;

              return (
                <StyledFormItem
                  label={intl.formatMessage({
                    id: 'views.users.form.label.password',
                  })}
                  name="password"
                  rules={[{ required: isRequired && !isEdit }]}
                >
                  <StyledInputPassword />
                </StyledFormItem>
              );
            }}
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'common.field.published',
            })}
            name="published"
            valuePropName="checked"
          >
            <Switch />
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem style={{ textAlign: 'center' }}>
            <StyledButton htmlType="submit" type="primary" loading={isLoading}>
              {intl.formatMessage({ id: 'common.btn.save' })}
            </StyledButton>
          </StyledFormItem>
        </Col>
      </Row>
    </StyledForm>
  );
}
