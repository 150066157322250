import { useMemo } from 'react';
import { useLocales } from 'api/localeApi';
import { StyledSelect } from 'styles/overrides';
import _ from 'lodash';
import { filterOptions } from 'utils/formatUtils';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';

export default function LocaleSelector({
  relatedEntityId,
  localizations,
  onChange,
  value,
  isFilter = false,
  ...restProps
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const mappingLocalizations = useMemo(() => {
    return _.reduce(
      localizations,
      (acc, item) => {
        acc[item.locale] = item.id;

        return acc;
      },
      {},
    );
  }, [localizations]);
  const { data, isLoading } = useLocales();

  const handleChange = (value) => {
    if (!isFilter) {
      const splittedPathname = location.pathname.split('/');
      const id = mappingLocalizations[value];

      navigate({
        pathname: id
          ? `/${splittedPathname[1]}/${id}`
          : `/${splittedPathname[1]}/create`,
        search: `?${createSearchParams({
          locale_code: value,
          related_entity_id: relatedEntityId ?? '',
        })}`,
      });
    }

    onChange?.(value);
  };

  return (
    <StyledSelect
      // options={data}
      loading={isLoading}
      showSearch
      optionFilterProp="label"
      filterOption={filterOptions}
      {...restProps}
      onChange={handleChange}
      value={value}
    >
      {_.map(data, (option) => (
        <StyledSelect.Option key={option.id} value={option.code}>
          {option.name}
        </StyledSelect.Option>
      ))}
    </StyledSelect>
  );
}
