import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const localeApi = {
  getAll(params) {
    return axiosClient
      .get('/locales', { params })
      .then((response) => response?.data || []);
  },
  getById(id, params) {
    return axiosClient
      .get(`/locales/${id}`, { params })
      .then((response) => response?.data || null);
  },
  create(data) {
    return axiosClient.post('/locales', data);
  },
  update(id, data) {
    return axiosClient.patch(`/locales/${id}`, data);
  },
  delete(ids) {
    return axiosClient.delete('/locales', { data: { ids } });
  },
};

export const useLocales = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['locales', 'list', params],
    queryFn: () => localeApi.getAll(params),
    ...options,
  });
};

export const useLocale = ({ id, params, options } = {}) => {
  return useQuery({
    queryKey: ['locales', 'details', id, params],
    queryFn: () => localeApi.getById(id, params),
    ...options,
  });
};

export const useCreateLocale = () => {
  return useMutation(localeApi.create);
};

export const useUpdateLocale = (id) => {
  return useMutation((data) => localeApi.update(id, data));
};

export const useDeleteLocale = () => {
  return useMutation(localeApi.delete);
};
