import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const productApi = {
  getAll(params) {
    return axiosClient.get('/products', { params });
  },
  getById(id, params) {
    return axiosClient
      .get(`/products/${id}`, { params })
      .then((response) => response?.data || null);
  },
  create(data) {
    return axiosClient.post('/products', data);
  },
  update(id, data) {
    return axiosClient.patch(`/products/${id}`, data);
  },
  delete(ids) {
    return axiosClient.delete(`/products`, { data: { ids } });
  },
  getNonLocalizedFields(id) {
    return axiosClient
      .get(`/products/get-non-localized/${id}`)
      .then((response) => response?.data || []);
  },
  getAllPublic(params) {
    return axiosClient.get(`/products`, { params });
  },
};

export const useProducts = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['products', 'list', params],
    queryFn: () => productApi.getAll(params),
    ...options,
  });
};

export const useProduct = ({ id, params, options } = {}) => {
  return useQuery({
    queryKey: ['products', 'details', id, params],
    queryFn: () => productApi.getById(id, params),
    ...options,
  });
};

export const useCreateProduct = () => {
  return useMutation(productApi.create);
};

export const useUpdateProduct = (id) => {
  return useMutation((data) => productApi.update(id, data));
};

export const useDeleteProduct = () => {
  return useMutation({
    mutationFn: (ids) => productApi.delete(ids),
  });
};

export const useGetNonLocalizedProductFields = ({ id, options }) => {
  return useQuery({
    queryKey: ['products', 'non-localized-fields', id],
    queryFn: () => productApi.getNonLocalizedFields(id),
    ...options,
  });
};

export const useProductsPublic = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['products', 'list', params],
    queryFn: () => productApi.getAllPublic(params),
    ...options,
  });
};