import { useIntl } from 'react-intl';
import { Row, Col, Form, Typography } from 'antd';
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledInput,
} from 'styles/overrides';
import { useLocales } from 'api/localeApi';
import _ from 'lodash';

export default function SettingForm({
  onSubmit,
  initialValues,
  isLoading = false,
  isGender,
}) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const { data: locales = [] } = useLocales();

  const handleSubmit = (values) => {
    onSubmit?.(values);
  };

  return (
    <StyledForm
      layout="vertical"
      size="large"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <Row gutter={[32, 0]}>
        {isGender ? (
          <>
            {_.map(
              ['1', '2'].map((item) => {
                return (
                  <Col span={24} sm={12} key={item}>
                    <Typography.Title level={4}>
                      {intl.formatMessage({ id: `common.gender.${item}` })}
                    </Typography.Title>

                    <Row>
                      {_.map(locales, (locale) => (
                        <Col span={24} key={locale.id}>
                          <StyledFormItem
                            label={locale.name}
                            name={['value', item, locale.code]}
                            rules={[{ required: false }]}
                          >
                            <StyledInput />
                          </StyledFormItem>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                );
              }),
            )}
          </>
        ) : (
          <>
            {_.map(locales, (locale) => (
              <Col span={24} sm={12} key={locale.id}>
                <StyledFormItem
                  label={locale.name}
                  name={['value', locale.code]}
                  rules={[{ required: false }]}
                >
                  <StyledInput />
                </StyledFormItem>
              </Col>
            ))}
          </>
        )}

        <Col span={24}>
          <StyledFormItem style={{ textAlign: 'center' }}>
            <StyledButton htmlType="submit" type="primary" loading={isLoading}>
              {intl.formatMessage({ id: 'common.btn.save' })}
            </StyledButton>
          </StyledFormItem>
        </Col>
      </Row>
    </StyledForm>
  );
}
