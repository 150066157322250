import { useParams } from 'react-router-dom';
import { Notification } from 'components/Notification';
import { ContentWrapper } from 'components/layout';
import { useLocale, useUpdateLocale } from 'api/localeApi';
import { LocaleForm } from './components';
import { useIntl } from 'react-intl';
import { Loading } from 'components/loading';

export default function ViewLocale() {
  const intl = useIntl();
  const { id } = useParams();
  const { data, isLoading, isFetching } = useLocale({
    id,
  });
  const updateMutation = useUpdateLocale(id);

  const handleSubmit = (values) => {
    updateMutation.mutate(values, {
      onSuccess() {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.updateSuccess`,
          }),
        );
      },
      onError(error) {
        Notification(
          'error',
          error.message ?? intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  if (isLoading || isFetching) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  return (
    <ContentWrapper title="Chỉnh sửa">
      <LocaleForm
        onSubmit={handleSubmit}
        isLoading={updateMutation.isLoading}
        initialValues={data}
      />
    </ContentWrapper>
  );
}
