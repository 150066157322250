import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import {
  calcCurrentPageWithNum,
  calcIndexByOrder,
  removeFalsyKeys,
} from 'utils/common';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { Table } from 'components/table';
import { formatTime } from 'utils/timeUtils';
import { formatInputNumber } from 'utils/formatUtils';
import { useOrders, useDeleteOrders, useExportOrder } from 'api/orderApi';
import { StyledActions, StyledButton } from 'styles/overrides';
import OrderFilterSearch from '../OrderFilterSearch';
import { Notification } from 'components/Notification';
import { DownloadTemplate } from 'components/download-template';

export default function OrderTable() {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams({ hasLocale: false });
  const { data, isLoading, isFetching, refetch } = useOrders({
    params: search,
  });
  const deleteMutation = useDeleteOrders();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const exportMutation = useExportOrder(search);

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => {
        return calcIndexByOrder({
          index,
          page: search.page,
          limit: search.page_size,
        });
      },
    },
    {
      title: intl.formatMessage({ id: 'views.orders.table.seri' }),
      dataIndex: 'seri',
    },
    {
      title: intl.formatMessage({ id: 'views.orders.table.keyNumber' }),
      dataIndex: 'key_number',
    },
    {
      title: intl.formatMessage({ id: 'views.orders.table.name' }),
      dataIndex: ['product', 'name'],
    },
    {
      title: intl.formatMessage({ id: 'views.orders.table.gender' }),
      dataIndex: 'gender',
    },
    {
      title: intl.formatMessage({ id: 'views.orders.table.price' }),
      dataIndex: 'price',
      render: (value) => formatInputNumber(value),
    },
    {
      title: intl.formatMessage({ id: 'views.orders.table.receptionist' }),
      dataIndex: ['receptionist', 'code'],
    },
    {
      title: intl.formatMessage({ id: 'views.orders.table.technician' }),
      dataIndex: ['technician', 'code'],
    },
    {
      title: intl.formatMessage({ id: 'common.table.createdAt' }),
      dataIndex: ['created_at'],
      render: (value) => formatTime(value),
    },
    {
      render: ({ id }) => (
        <Link to={`${id}`}>
          <StyledButton type="primary">
            {intl.formatMessage({ id: 'common.btn.seeDetails' })}
          </StyledButton>
        </Link>
      ),
    },
  ];

  const handleChangeTable = ({ current: page, pageSize: limit }) => {
    setSearch(removeFalsyKeys({ ...search, page, page_size: limit }));
  };

  const handleDelete = () => {
    if (selectedRowKeys.length <= 0) return;

    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.deleteTitle' }),
      okText: intl.formatMessage({ id: 'common.btn.confirm' }),
      onOk() {
        return deleteMutation.mutateAsync(selectedRowKeys, {
          onSuccess() {
            const calculatedPage = calcCurrentPageWithNum({
              page: search.page,
              limit: search.page_size,
              total: data?.meta?.total,
              num: selectedRowKeys.length,
            });

            if (calculatedPage !== search.page) {
              setSearch(
                removeFalsyKeys({
                  ...search,
                  page: calculatedPage,
                }),
              );
            } else {
              refetch();
            }

            setSelectedRowKeys([]);
            Notification(
              'success',
              intl.formatMessage({ id: 'message.deleteSuccess' }),
            );
          },
          onError(error) {
            Notification(
              'error',
              error?.message ||
                intl.formatMessage({ id: 'message.commonError' }),
            );
          },
        });
      },
      onCancel() {},
    });
  };

  return (
    <>
      <OrderFilterSearch
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
      />

      <StyledActions size={24}>
        <DownloadTemplate
          mutation={exportMutation}
          title={intl.formatMessage({ id: 'common.btn.export' })}
        />

        <StyledButton
          size="large"
          onClick={handleDelete}
          disabled={selectedRowKeys.length === 0}
          type="danger"
        >
          {intl.formatMessage({ id: 'common.btn.delete' })}
        </StyledButton>
      </StyledActions>

      <Table
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
          },
          preserveSelectedRowKeys: true,
        }}
        columns={columns}
        dataSource={data?.data || []}
        loading={isLoading || deleteMutation.isLoading || isFetching}
        pagination={{
          current: search?.page,
          pageSize: search?.page_size,
          total: data?.meta?.total || 0,
        }}
        onChange={handleChangeTable}
      />
    </>
  );
}
