import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const branchApi = {
  getAll(params) {
    return axiosClient.get('/branches', { params });
  },
  getById(id, params) {
    return axiosClient
      .get(`/branches/${id}`, { params })
      .then((response) => response?.data || null);
  },
  create(data) {
    return axiosClient.post('/branches', data);
  },
  update(id, data) {
    return axiosClient.patch(`/branches/${id}`, data);
  },
  delete(ids) {
    return axiosClient.delete('/branches', { data: { ids } });
  },
};

export const useBranches = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['branches', 'list', params],
    queryFn: () => branchApi.getAll(params),
    ...options,
  });
};

export const useBranch = ({ id, params, options } = {}) => {
  return useQuery({
    queryKey: ['branches', 'details', id, params],
    queryFn: () => branchApi.getById(id, params),
    ...options,
  });
};

export const useCreateBranch = () => {
  return useMutation(branchApi.create);
};

export const useUpdateBranch = (id) => {
  return useMutation((data) => branchApi.update(id, data));
};

export const useDeleteBranches = () => {
  return useMutation(branchApi.delete);
};
