import _ from 'lodash';
import { useIntl } from 'react-intl';
import { Row, Col, Spin } from 'antd';
import { useGetTotalStatistic } from 'api/statisticApi';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import StatisticTotalItem from '../StatisticTotalItem';
import { formatCurrency } from 'utils/formatUtils';

export default function StatisticTotal() {
  const intl = useIntl();
  const [search] = useCustomSearchParams();
  const { data, isLoading, isFetching } = useGetTotalStatistic({
    params: search,
  });

  if (isLoading) {
    return null;
  }

  return (
    <Spin spinning={isFetching}>
      <Row gutter={[8, 8]}>
        {_.map(data, (value, key) => {
          const content = _.isObject(value)
            ? _.map(value, (v, k) => {
                return `${v} ${intl.formatMessage({
                  id: `views.dashboard.statistic.total.${k}`,
                })}`;
              }).join(' / ')
            : formatCurrency(value);

          return (
            <Col span={6} key={key}>
              <StatisticTotalItem
                title={intl.formatMessage({
                  id: `views.dashboard.statistic.total.${key}`,
                })}
                content={content}
                keyName={key}
              />
            </Col>
          );
        })}
      </Row>
    </Spin>
  );
}
