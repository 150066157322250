import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import {
  calcCurrentPageWithNum,
  calcIndexByOrder,
  removeFalsyKeys,
} from 'utils/common';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { Table } from 'components/table';
import { formatTime } from 'utils/timeUtils';
import { useQuestions, useDeleteQuestions } from 'api/questionApi';
import { StyledActions, StyledButton } from 'styles/overrides';
import QuestionFilterSearch from '../QuestionFilterSearch';
import { Notification } from 'components/Notification';
import { PATH_NAME_ACTION } from 'constants/routes';
import _ from 'lodash';
import { Publish } from 'components/common';

export default function QuestionTable() {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  const { data, isLoading, isFetching, refetch } = useQuestions({
    params: search,
  });
  const deleteMutation = useDeleteQuestions();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => {
        return calcIndexByOrder({
          index,
          page: search.page,
          limit: search.page_size,
        });
      },
    },
    {
      title: intl.formatMessage({ id: 'views.questions.table.content' }),
      dataIndex: 'content',
    },
    {
      title: intl.formatMessage({ id: 'views.questions.table.answers' }),
      dataIndex: ['answers'],
      render: (answers) => {
        return _.map(answers, (item) => <p key={item.id}>- {item.content}</p>);
      },
    },
    {
      title: intl.formatMessage({ id: 'views.questions.table.limit' }),
      dataIndex: ['limit'],
    },
    {
      title: intl.formatMessage({ id: 'views.questions.table.type' }),
      dataIndex: 'type',
    },
    {
      title: intl.formatMessage({ id: 'common.table.published' }),
      dataIndex: 'published',
      render: (value) => <Publish published={value} />,
    },
    {
      title: intl.formatMessage({ id: 'common.table.createdAt' }),
      dataIndex: 'created_at',
      render: (value) => formatTime(value),
    },
    {
      render: ({ id }) => (
        <Link to={`${id}`}>
          <StyledButton type="primary">
            {intl.formatMessage({ id: 'common.btn.seeDetails' })}
          </StyledButton>
        </Link>
      ),
    },
  ];

  const handleChangeTable = ({ current: page, pageSize: limit }) => {
    setSearch(removeFalsyKeys({ ...search, page, page_size: limit }));
  };

  const handleDelete = () => {
    if (selectedRowKeys.length <= 0) return;

    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.deleteTitle' }),
      okText: intl.formatMessage({ id: 'common.btn.confirm' }),
      onOk() {
        return deleteMutation.mutateAsync(selectedRowKeys, {
          onSuccess() {
            const calculatedPage = calcCurrentPageWithNum({
              page: search.page,
              limit: search.page_size,
              total: data?.meta?.total,
              num: selectedRowKeys.length,
            });

            if (calculatedPage !== search.page) {
              setSearch(
                removeFalsyKeys({
                  ...search,
                  page: calculatedPage,
                }),
              );
            } else {
              refetch();
            }

            setSelectedRowKeys([]);
            Notification(
              'success',
              intl.formatMessage({ id: 'message.deleteSuccess' }),
            );
          },
          onError(error) {
            Notification(
              'error',
              error?.message ||
                intl.formatMessage({ id: 'message.commonError' }),
            );
          },
        });
      },
      onCancel() {},
    });
  };

  return (
    <>
      <QuestionFilterSearch
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
      />

      <StyledActions size={24}>
        <Link to={PATH_NAME_ACTION.CREATE}>
          <StyledButton type="primary">
            {intl.formatMessage({ id: 'common.btn.addNew' })}
          </StyledButton>
        </Link>

        <StyledButton
          onClick={handleDelete}
          disabled={selectedRowKeys.length === 0}
        >
          {intl.formatMessage({ id: 'common.btn.delete' })}
        </StyledButton>
      </StyledActions>

      <Table
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
          },
          preserveSelectedRowKeys: true,
        }}
        columns={columns}
        dataSource={data?.data || []}
        loading={isLoading || deleteMutation.isLoading || isFetching}
        pagination={{
          current: search?.page,
          pageSize: search?.page_size,
          total: data?.meta?.total || 0,
        }}
        onChange={handleChangeTable}
      />
    </>
  );
}
