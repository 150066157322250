import { useAnswers } from 'api/answerApi';
import { StyledSelect } from 'styles/overrides';
import _ from 'lodash';
import { filterOptions } from 'utils/formatUtils';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';

export default function AnswerSelector({
  onChange,
  value,
  params = {},
  ...restProps
}) {
  const [search] = useCustomSearchParams();
  const { data = [], isLoading } = useAnswers({
    params: {
      page_size: 1000,
      // locale_code: _.get(search, ['locale_code']),
      ...params,
    },
    options: {
      select: (response) =>
        _.map(response?.data, ({ id, content }) => ({
          label: content,
          value: id.toString(),
        })) ?? [],
    },
  });

  return (
    <StyledSelect
      options={data}
      loading={isLoading}
      showSearch
      optionFilterProp="label"
      filterOption={filterOptions}
      {...restProps}
      onChange={onChange}
      value={value}
    />
  );
}
