import { useIntl } from 'react-intl';
import { Row, Col, Form } from 'antd';
import {
  StyledForm,
  StyledFormItem,
  StyledInput,
  StyledInputNumber,
  StyledButton,
} from 'styles/overrides';
import { LocaleSelector } from 'components/selectors';

export default function AnswerForm({
  onSubmit,
  relatedEntityId,
  localizations,
  initialValues,
  isLoading = false,
}) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    const data = {
      ...values,
      inv_answer_id: relatedEntityId ?? null,
    };

    onSubmit?.(data);
  };

  return (
    <StyledForm
      layout="vertical"
      size="large"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <Row gutter={[32, 0]}>
        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.answers.form.label.content',
            })}
            name="content"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'common.field.order',
            })}
            name="order"
            rules={[{ required: false }]}
          >
            <StyledInputNumber min={1} />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'common.field.locale',
            })}
            name="locale"
            rules={[{ required: true }]}
          >
            <LocaleSelector
              relatedEntityId={relatedEntityId}
              localizations={localizations}
            />
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem style={{ textAlign: 'center' }}>
            <StyledButton htmlType="submit" type="primary" loading={isLoading}>
              {intl.formatMessage({ id: 'common.btn.save' })}
            </StyledButton>
          </StyledFormItem>
        </Col>
      </Row>
    </StyledForm>
  );
}
