import { useParams } from 'react-router-dom';
import { ContentWrapper } from 'components/layout';
import { useOrder } from 'api/orderApi';
import { OrderForm } from './components';
import { Loading } from 'components/loading';
import _ from 'lodash';
import { useQuestions } from '../../api/publicApi';

function normalizeData(data) {
  if (!data) {
    return null;
  }

  const {
    gender,
    key_number,
    locale,
    price,
    product,
    receptionist,
    technician,
    seri,
    option,
  } = data;

  return {
    gender,
    key_number,
    locale: locale?.code,
    price,
    product_id: product?.id?.toString(),
    receptionist_code: receptionist?.code,
    technician_code: technician?.code,
    seri,
    questions: _.reduce(
      option,
      (acc, item) => {
        console.log(item);
        const { question_id, question_type, answers } = item;

        acc[question_id] =
          question_type === 'radio'
            ? answers[0]?.answer_id
            : _.map(answers, (answer) => answer.answer_id);

        return acc;
      },
      {},
    ),
  };
}

export default function ViewOrder() {
  const { id } = useParams();
  const { data, isLoading, isFetching } = useOrder({
    id,
  });
  const {
    data: questions,
    isLoading: isLoadingQuestions,
    isFetching: isFetchingQuestions,
  } = useQuestions({
    params: {
      locale_code: data?.locale?.code,
      product_id: data?.product?.id,
    },
    options: {
      enabled: !!data,
    },
  });

  if (isLoading || isFetching || isLoadingQuestions || isFetchingQuestions) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  console.log(data, questions);

  return (
    <ContentWrapper title="Chi tiết">
      <OrderForm
        questions={questions}
        initialValues={normalizeData(data)}
        localeCode={data?.locale?.code}
      />
    </ContentWrapper>
  );
}
