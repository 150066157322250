import { Form } from 'antd';
import { DEFAULT_PAGINATION } from 'constants/common';
import { FilterForm } from 'components/form';
import { StyledSpace, StyledFormItem } from 'styles/overrides';
import { FromToDateSelector } from 'components/selectors';
import { normalizeSearchData, normalizeSubmitSearchData } from 'utils/common';

export default function StatisticFilterSearch({ onSubmit, initialValues }) {
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    onSubmit(
      normalizeSubmitSearchData({
        ...initialValues,
        ...values,
        page: DEFAULT_PAGINATION.PAGE,
      }),
    );
  };

  return (
    <FilterForm
      onFinish={handleSubmit}
      initialValues={normalizeSearchData(initialValues)}
      formInstance={form}
    >
      <StyledSpace size={16}>
        <StyledFormItem name="fromToDate">
          <FromToDateSelector allowClear format="DD/MM/YYYY" />
        </StyledFormItem>
      </StyledSpace>
    </FilterForm>
  );
}
