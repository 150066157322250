export const findMatchedMenuItemByPathname = (items, pathname) => {
  return items.find((item) => item.url === pathname);
};



export const findRelatedMatchedMenuItemByPathname = (items, pathname) => {
  for (const item of items) {
    if (item.children) {
      return findRelatedMatchedMenuItemByPathname(item.children, pathname);
    }
    else if (pathname.includes(item.url)) {
      // console.log(item)
      return item;
    }
  }
  
  return null; // Return null if no match is found
};

export const findRelatedMenuItemsByMatchedMenuItem = (matchedItem, items = []) => {
  // console.log('matched item', matchedItem);
  return items.reduce((menuItems, item) => {
    if (item.children) {
      const relatedMenuItems = findRelatedMenuItemsByMatchedMenuItem(matchedItem, item.children);
      return menuItems.concat(relatedMenuItems);
    } else if (matchedItem.key === item.key) {
      menuItems.push(item);
    }
    return menuItems;
  }, []);
};
export const filteredMenuItemsByCurrentRole = (menuItems = [], currentRole) => {
  return menuItems.filter((item) => item?.roles?.includes(currentRole));
};
