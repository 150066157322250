import { Row, Col } from 'antd';
import StatisticTotal from '../StatisticTotal';
import StatisticRevenue from '../StatisticRevenue';
import StatisticOrderQuantity from '../StatisticOrderQuantity';
import StatisticOrderQuantityByLocale from '../StatisticOrderQuantityByLocale';
import StatisticProductQuantity from '../StatisticProductQuantity';
import StatisticFilterSearch from '../StatisticFilterSearch';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';

export default function StatisticBlock() {
  const [search, setSearch] = useCustomSearchParams();

  return (
    <>
      <StatisticFilterSearch
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
      />
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <StatisticTotal />
        </Col>

        <Col span={24}>
          <StatisticRevenue />
        </Col>

        <Col span={24}>
          <StatisticOrderQuantity />
        </Col>

        <Col span={24}>
          <StatisticOrderQuantityByLocale />
        </Col>

        <Col span={24}>
          <StatisticProductQuantity />
        </Col>
      </Row>
    </>
  );
}
