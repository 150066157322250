import React, { useMemo } from 'react';
import { Typography } from 'antd';
import { Column } from '@ant-design/plots';
import BoxWrapper from '../BoxWrapper';
import { formatInputNumber } from 'utils/formatUtils';

export default function GroupedColumnChart({ name, data = [] }) {
  const config = useMemo(() => {
    return {
      data,
      xField: 'xField',
      yField: 'yField',
      seriesField: 'name',
      isGroup: true,

      xAxis: {
        label: {
          autoHide: false,
          autoRotate: true,
          style: { fontSize: 10 },
        },
      },
      yAxis: {
        label: {
          formatter: (text) => formatInputNumber(text),
        },
      },
      minColumnWidth: 40,
      maxColumnWidth: 40,
      label: {
        position: 'middle',
        formatter: (item) => {
          if (!+item.yField) {
            return null;
          }

          return formatInputNumber(item.yField);
        },
        style: {
          fill: '#000',
        },
      },

      // label: {
      //   position: 'middle',
      //   layout: [
      //     {
      //       type: 'interval-adjust-position',
      //     },
      //     {
      //       type: 'interval-hide-overlap',
      //     },
      //     {
      //       type: 'adjust-color',
      //     },
      //   ],
      // },
      // // color: _.map(data, (item) => item.color),
      // xAxis: {
      //   label: {
      //     autoHide: false,
      //     autoRotate: true,
      //     style: { fontSize: 10 },
      //   },
      // },
      // minColumnWidth: 40,
      // maxColumnWidth: 40,
      // legend: false,
      // annotations: _.map(data, (item) => ({
      //   type: 'text',
      //   content: `${item.value}`,
      //   style: { textAlign: 'center', fontSize: 14, fill: 'var(--black)' },
      //   offsetY: -10,
      //   position: [item.name, item.value],
      // })),
      // padding: [35, 35, 100, 35],
      // yAxis: {
      //   label: {
      //     formatter: (text) => formatInputNumber(text),
      //   },
      // },
      legend: {
        position: 'bottom',
        marker: {
          symbol: 'circle',
        },
      },
    };
  }, [data]);

  return (
    <BoxWrapper>
      <Typography.Title level={3} style={{ textAlign: 'center' }}>
        {name}
      </Typography.Title>

      <Column {...config} />
    </BoxWrapper>
  );
}
