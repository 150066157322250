import { useIntl } from 'react-intl';
import { Tag } from 'antd';
import { COLOR_SUCCESS, COLOR_ERROR } from 'constants/colors';

export default function Publish({ published }) {
  const intl = useIntl();

  return (
    <Tag color={!!published ? COLOR_SUCCESS : COLOR_ERROR}>
      {intl.formatMessage({ id: `common.publish.${!!published}` })}
    </Tag>
  );
}
