import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const orderApi = {
  getAll(params) {
    return axiosClient.get('/orders', { params });
  },
  getById(id, params) {
    return axiosClient
      .get(`/orders/${id}`, { params })
      .then((response) => response?.data || null);
  },
  create(data) {
    return axiosClient.post('/orders', data);
  },
  delete(ids) {
    return axiosClient.delete('/orders', { data: { ids } });
  },
  export(params) {
    return axiosClient.get('/export/orders', { params, timeout: 3000000 });
  },
};

export const useOrders = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['orders', 'list', params],
    queryFn: () => orderApi.getAll(params),
    ...options,
  });
};

export const useOrder = ({ id, params, options } = {}) => {
  return useQuery({
    queryKey: ['orders', 'details', id, params],
    queryFn: () => orderApi.getById(id, params),
    ...options,
  });
};

export const useCreateOrder = () => {
  return useMutation(orderApi.create);
};

export const useUpdateOrder = (id) => {
  return useMutation((data) => orderApi.update(id, data));
};

export const useDeleteOrders = () => {
  return useMutation(orderApi.delete);
};

export const useExportOrder = (params) => {
  return useMutation({
    mutationFn: () => orderApi.export(params),
  });
};
