import { useIntl } from 'react-intl';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { Alert, Spin, Form, Typography } from 'antd';
import {
  useVerifyCodeGrantPermission,
  useAcceptGrantPermission,
} from 'api/exportApi';
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledInputNumber,
} from 'styles/overrides';
import { Notification } from 'components/Notification';

export default function RequestPermissionExport() {
  const intl = useIntl();
  const [search] = useCustomSearchParams();
  const { code } = search;
  const [form] = Form.useForm();
  const {
    data: verifyCode,
    isLoading: isLoadingVerifyCode,
    refetch,
  } = useVerifyCodeGrantPermission({ code });
  const acceptGrantPermissionMutation = useAcceptGrantPermission();

  const handleSubmit = (values) => {
    acceptGrantPermissionMutation.mutate(
      { ...values, code },
      {
        onSuccess() {
          Notification(
            'success',
            intl.formatMessage({
              id: 'message.commonSuccess',
            }),
          );
          refetch();
        },
        onError(error) {
          Notification(
            'error',
            error.message ||
              intl.formatMessage({
                id: 'message.commonError',
              }),
          );
        },
      },
    );
  };

  return (
    <Spin
      spinning={isLoadingVerifyCode || acceptGrantPermissionMutation.isLoading}
    >
      {!isLoadingVerifyCode && (
        <>
          {!!verifyCode?.check_code ? (
            <div
              style={{
                maxWidth: 400,
                width: '100%',
                backgroundColor: 'var(--white)',
                borderRadius: 8,
                padding: '16px 32px',
              }}
            >
              <Typography.Title level={3}>
                {intl.formatMessage({
                  id: 'views.exports.title.grantPermission',
                })}
              </Typography.Title>

              <StyledForm
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                initialValues={{ hours: 1 }}
              >
                <StyledFormItem
                  name="hours"
                  label={intl.formatMessage({
                    id: 'views.exports.field.hours',
                  })}
                  rules={[{ required: true }]}
                >
                  <StyledInputNumber min={1} controls={false} />
                </StyledFormItem>

                <StyledFormItem>
                  <StyledButton type="danger" htmlType="submit">
                    {intl.formatMessage({ id: 'common.btn.confirm' })}
                  </StyledButton>
                </StyledFormItem>
              </StyledForm>
            </div>
          ) : (
            <Alert type="error" message={verifyCode?.message} />
          )}
        </>
      )}
    </Spin>
  );
}
