import { useIntl } from 'react-intl';
import { DatePicker } from 'antd';
import { DATE_FORMAT } from 'constants/common';

export default function FromToDateSelector({ onChange, value, ...restProps }) {
  const intl = useIntl();

  return (
    <DatePicker.RangePicker
      format={DATE_FORMAT}
      placeholder={[
        intl.formatMessage({ id: 'common.placeholder.fromDate' }),
        intl.formatMessage({ id: 'common.placeholder.toDate' }),
      ]}
      {...restProps}
      value={value}
      onChange={onChange}
    />
  );
}
