import axios from 'axios';
import queryString from 'query-string';

import { STORAGE_KEY } from 'constants/common';
import { doLogout } from 'store/slices/authSlice';
import { handleErrorMessage } from 'utils';

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-App-Version': process.env.REACT_APP_VERSION,
  },
  timeout: 30000,
  paramsSerializer: (params) =>
    queryString.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
    }),
});

const handleCheckGetCustomerByPhoneUrl = (config) => {
  const url = '/customers/phone/';

  if (config.method === 'get' && config.url?.includes(url)) return true;

  // TODO: Improve with check phone number

  return false;
};

const httpService = {
  setupInterceptors: (store) => {
    axiosClient.interceptors.request.use(
      (config) => {
        const isGetCustomerByPhoneUrl =
          handleCheckGetCustomerByPhoneUrl(config);

        const token = localStorage.getItem(
          isGetCustomerByPhoneUrl ? STORAGE_KEY.OTP_TOKEN : STORAGE_KEY.TOKEN,
        );

        if (token) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    axiosClient.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        const errorMessage = handleErrorMessage(error.response || error);
        console.log('errorMessage: ', errorMessage);

        if (error.response) {
          const { config, status } = error.response;

          const isGetCustomerByPhoneUrl =
            handleCheckGetCustomerByPhoneUrl(config);

          if (!isGetCustomerByPhoneUrl && (status === 401 || status === 403)) {
            store.dispatch(doLogout());
          }
        }

        // Notification('error', errorMessage.message);

        return Promise.reject(errorMessage);
      },
    );
  },
};

export default axiosClient;
export { httpService };
