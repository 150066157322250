import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGINATION } from 'constants/common';

export const useCustomSearchParams = ({ hasLocale = true } = {}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const localeCode = useSelector((state) => state.app.localeCode);

  const transformedSearchParams = useMemo(() => {
    const searchParamsAsObject = Object.fromEntries(
      new URLSearchParams(searchParams),
    );

    return {
      ...searchParamsAsObject,
      page: +searchParamsAsObject.page || DEFAULT_PAGINATION.PAGE,
      page_size: +searchParamsAsObject.page_size || DEFAULT_PAGINATION.LIMIT,
      locale_code:
        searchParamsAsObject.locale_code ||
        (hasLocale ? localeCode : undefined),
    };
  }, [searchParams]);

  return [transformedSearchParams, setSearchParams];
};
