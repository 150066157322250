import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

const StyledAuthLayout = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    to right top,
    #017bc0,
    #7398d0,
    #abb7df,
    #d8daef,
    #ffffff
  );
  position: relative;
`;

const StyledAuthLayoutWrapper = styled.div`
  flex-grow: 1;
  padding: 32px 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export default function AuthLayout() {
  return (
    <StyledAuthLayout>
      <StyledAuthLayoutWrapper>
        <Outlet />
      </StyledAuthLayoutWrapper>
    </StyledAuthLayout>
  );
}
