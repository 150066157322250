import GroupedColumnChart from '../GroupedColumnChart';
import { useGetTopOrder } from 'api/dashboardApi';
import _ from 'lodash';

function normalizeData(data) {
  return _.reduce(
    data,
    (acc, item) => {
      const { name, ...value } = item;

      const mapping = _.map(value, (v, k) => ({
        name: _.capitalize(k),
        xField: name,
        yField: v,
      }));

      acc.push(...mapping);

      return acc;
    },
    [],
  );
}

export default function TopOrdersBlock() {
  const { data, isLoading } = useGetTopOrder();

  if (isLoading) {
    return null;
  }

  return (
    <GroupedColumnChart
      data={normalizeData(data)}
      name="Top dịch vụ order nhiều"
    />
  );
}
