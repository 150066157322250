import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const footerApi = {
  getAll(params) {
    return axiosClient.get('/footers', { params });
  },
  getById(id, params) {
    return axiosClient
      .get(`/footers/${id}`, { params })
      .then((response) => response?.data || null);
  },
  create(data) {
    return axiosClient.post('/footers', data);
  },
  update(id, data) {
    return axiosClient.patch(`/footers/${id}`, data);
  },
  delete(ids) {
    return axiosClient.delete('/footers', { data: { ids } });
  },
};

export const useFooters = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['footers', 'list', params],
    queryFn: () => footerApi.getAll(params),
    ...options,
  });
};

export const useFooter = ({ id, params, options } = {}) => {
  return useQuery({
    queryKey: ['footers', 'details', id, params],
    queryFn: () => footerApi.getById(id, params),
    ...options,
  });
};

export const useCreateFooter = () => {
  return useMutation(footerApi.create);
};

export const useUpdateFooter = (id) => {
  return useMutation((data) => footerApi.update(id, data));
};

export const useDeleteFooters = () => {
  return useMutation(footerApi.delete);
};
