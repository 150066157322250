import { useParams } from 'react-router-dom';
import { Notification } from 'components/Notification';
import { ContentWrapper } from 'components/layout';
import { useFooter, useUpdateFooter } from 'api/footerApi';
import { FooterForm } from './components';
import { useIntl } from 'react-intl';
import { Loading } from 'components/loading';

function normalizeData(data) {
  if (!data) {
    return null;
  }

  const { content, locale } = data;

  return {
    content,
    locale: locale?.code,
  };
}

export default function ViewFooter() {
  const intl = useIntl();
  const { id } = useParams();
  const { data, isLoading, isFetching } = useFooter({
    id,
  });
  const updateMutation = useUpdateFooter(id);

  const handleSubmit = (values) => {
    updateMutation.mutate(values, {
      onSuccess() {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.updateSuccess`,
          }),
        );
      },
      onError(error) {
        Notification(
          'error',
          error.message ?? intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  if (isLoading || isFetching) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  return (
    <ContentWrapper title="Chỉnh sửa">
      <FooterForm
        onSubmit={handleSubmit}
        isLoading={updateMutation.isLoading}
        initialValues={normalizeData(data)}
      />
    </ContentWrapper>
  );
}
