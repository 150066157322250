import { useIntl } from 'react-intl';
import { Row, Col, Form, Checkbox, Radio } from 'antd';
import {
  // StyledButton,
  StyledForm,
  StyledFormItem,
  StyledInput,
  StyledInputNumber,
} from 'styles/overrides';
import {
  LocaleSelector,
  GenderSelector,
  ReceptionistSelector,
  TechnicianSelector,
  ProductSelector,
} from 'components/selectors';
import _ from 'lodash';

export default function OrderForm({
  localeCode,
  onSubmit,
  initialValues,
  isLoading = false,
  questions = [],
}) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    console.log({ values });
  };

  return (
    <StyledForm
      layout="vertical"
      size="large"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
    >
      <Row gutter={[32, 0]}>
        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.orders.form.label.keyNumber',
            })}
            name="key_number"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'common.field.locale',
            })}
            name="locale"
            rules={[{ required: true }]}
          >
            <LocaleSelector isFilter disabled />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.orders.form.label.productId',
            })}
            name="product_id"
            rules={[{ required: true }]}
          >
            <ProductSelector params={{ locale_code: localeCode }} />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.orders.form.label.gender',
            })}
            name="gender"
            rules={[{ required: true }]}
          >
            <GenderSelector />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.orders.form.label.receptionistCode',
            })}
            name="receptionist_code"
            rules={[{ required: true }]}
          >
            <ReceptionistSelector />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.orders.form.label.technicianCode',
            })}
            name="technician_code"
            rules={[{ required: true }]}
          >
            <TechnicianSelector />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.orders.form.label.price',
            })}
            name="price"
            rules={[{ required: false }]}
          >
            <StyledInputNumber controls={false} disabled />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.orders.form.label.seri',
            })}
            name="seri"
            rules={[{ required: false }]}
          >
            <StyledInput disabled />
          </StyledFormItem>
        </Col>

        <Col span={24}>
          {questions.length > 0 &&
            questions.map((question) => {
              const { id, content, require, answers, limit, type } = question;

              const normalizeAnswers = _.map(answers, (item) => ({
                label: item.content,
                value: item.id.toString(),
              }));

              return (
                <StyledFormItem
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues?.['questions']?.[`${id}`] !==
                    currentValues?.['questions']?.[`${id}`]
                  }
                  key={id}
                >
                  {({ getFieldValue }) => {
                    if (type === 'checkbox') {
                      const options =
                        getFieldValue(['questions', `${id}`]) ?? [];

                      let mappingOptions = [...normalizeAnswers];

                      if (options.length >= limit) {
                        mappingOptions = _.map(mappingOptions, (option) => ({
                          ...option,
                          disabled: !options.includes(option.value),
                        }));
                      }

                      return (
                        <StyledFormItem
                          label={content}
                          name={['questions', `${id}`]}
                          rules={[{ required: !!require }]}
                          key={id}
                        >
                          <Checkbox.Group options={mappingOptions} />
                        </StyledFormItem>
                      );
                    }

                    if (type === 'radio') {
                      return (
                        <StyledFormItem
                          label={content}
                          name={['questions', `${id}`]}
                          rules={[{ required: !!require }]}
                          key={id}
                        >
                          <Radio.Group options={normalizeAnswers} />
                        </StyledFormItem>
                      );
                    }

                    return null;
                  }}
                </StyledFormItem>
              );
            })}
        </Col>

        {/* <Col span={24}>
          <StyledFormItem style={{ textAlign: 'center' }}>
            <StyledButton htmlType="submit" type="primary" loading={isLoading}>
              {intl.formatMessage({ id: 'common.btn.save' })}
            </StyledButton>
          </StyledFormItem>
        </Col> */}
      </Row>
    </StyledForm>
  );
}
