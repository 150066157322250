/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { getCurrentUser } from 'store/slices/authSlice';
import { StyledSider, StyledSiderUserInfo } from 'styles/overrides';
import { UserInfo } from 'components/user/UserInfo';

import { SiderMenu } from './SiderMenu';
import {
  findMatchedMenuItemByPathname,
  findRelatedMatchedMenuItemByPathname,
  findRelatedMenuItemsByMatchedMenuItem,
} from '../utils/handleMenuItems';
import { setBreadcrumbs } from 'store/slices/layoutSlice';
import _ from 'lodash';

export const Sider = ({ collapsed = false, items = [] }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUser = useSelector(getCurrentUser);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openSubMenuKeys, setSubMenuKeys] = useState([]);

  const normalizeMenuItem = useCallback((item) => {
    item.text = intl.formatMessage({ id: `layout.sider.${item.key}` });
    item.label = item.text;

    if (item.url) {
      item.label = <Link to={item.url}>{item.label}</Link>;
    }

    return item;
  }, []);

  const normalizeMenuItems = useCallback((items = []) => {
    return _.map(items, (item) => {
      const { children } = item;
      const newItem = normalizeMenuItem(item);

      if (children?.length > 0) {
        newItem.children = normalizeMenuItems(children);
      }

      return newItem;
    });
  }, []);
  function handleMenuOpenChange(keys){ //to change key of submenu
    setSubMenuKeys(keys) 
  }
  const menuItems = useMemo(() => normalizeMenuItems(items), [items]);

  useEffect(() => { //to set last submenu keys that we used 
    function getCurrentSubMenuItem(){
      const path = location.pathname
      const arrayOfPath = path.split('/')
      arrayOfPath.shift()
      setSubMenuKeys((prev) => {
        if(prev === arrayOfPath)
          return
        else
          return arrayOfPath
      })
    }
    getCurrentSubMenuItem()
  }, [])
  useEffect(() => {
    let matchedMenuItem = findMatchedMenuItemByPathname(
      menuItems,
      location.pathname,
    );

    if (!matchedMenuItem) {
      matchedMenuItem = findRelatedMatchedMenuItemByPathname(
        menuItems,
        location.pathname,
      );
    }

    if (!matchedMenuItem) {
      return;
    }
    const matchedMenuItems = findRelatedMenuItemsByMatchedMenuItem(
      matchedMenuItem,
      menuItems,
    );
    const layoutData = matchedMenuItems.reduce(
      (obj, curr) => {
        obj.selectedKeys.push(curr.key);
        obj.breadcrumbs.push({
          key: curr.key,
          label: curr.text ?? curr.label,
          url: curr.url,
        });

        return obj;
      },
      { selectedKeys: [], breadcrumbs: [] },
    );
    dispatch(setBreadcrumbs(layoutData.breadcrumbs));
    setSelectedKeys(layoutData.selectedKeys);
  }, [location.pathname]);

  return (
    <StyledSider trigger={null} collapsible collapsed={collapsed}>
      <StyledSiderUserInfo>
        <UserInfo
          isVertical
          fullName={currentUser.fullName}
          role={currentUser.role}
          isCollapsed={collapsed}
        />
      </StyledSiderUserInfo>

      <SiderMenu items={menuItems} selectedKeys={selectedKeys}  openKeys={openSubMenuKeys} onOpenChange={handleMenuOpenChange}/>
    </StyledSider>
  );
};
