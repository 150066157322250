import React from 'react';
import styled from 'styled-components';

const MAPPING_COLOR = {
  revenue: '#5d39ef',
  order: '#3859ef',
  product: '#03a6ee',
  gender: '#ef7639',
};

const StyledStatisticTotalItem = styled.div`
  color: var(--white);
  padding: 8px;

  h3 {
    font-size: 24px;
  }

  p {
    font-size: 20px;
  }
`;

export default function StatisticTotalItem({ title, content, keyName }) {
  return (
    <StyledStatisticTotalItem
      style={{ backgroundColor: MAPPING_COLOR[keyName] ?? 'var(--black)' }}
    >
      <h3>{title}</h3>
      <p>{content}</p>
    </StyledStatisticTotalItem>
  );
}
