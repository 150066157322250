import { Row, Col } from 'antd';
import RevenueBlock from '../RevenueBlock';
import OrderQuantityBlock from '../OrderQuantityBlock';
import OrderQuantityLocaleBlock from '../OrderQuantityLocaleBlock';
import TopOrdersBlock from '../TopOrdersBlock';

export default function ReviewDashboard() {
  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <RevenueBlock />
        </Col>

        <Col span={12}>
          <OrderQuantityBlock />
        </Col>

        <Col span={24}>
          <OrderQuantityLocaleBlock />
        </Col>

        <Col span={24}>
          <TopOrdersBlock />
        </Col>
      </Row>
    </div>
  );
}
