import { useIntl } from 'react-intl';
import { ContentWrapper } from 'components/layout';
import { useCreateQrBox } from 'api/qrBoxApi';
import { Notification } from 'components/Notification';
import { QRForm } from './components';

export default function AddQRBox() {
  const intl = useIntl();
  const createMutation = useCreateQrBox();

  const handleSubmit = (values) => {
    createMutation.mutate(values, {
      onSuccess() {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.createSuccess`,
          }),
        );
      },
      onError(error) {
        Notification(
          'error',
          error.message ?? intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  return (
    <ContentWrapper title="Tạo mới">
      <QRForm onSubmit={handleSubmit} isLoading={createMutation.isLoading} />
    </ContentWrapper>
  );
}
