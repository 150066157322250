import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const qrBoxApi = {
  getAll(params) {
    return axiosClient
      .get('/qr-boxes', { params })
      .then((response) => response?.data || []);
  },
  getById(id) {
    return axiosClient
      .get(`/qr-boxes/${id}`)
      .then((response) => response?.data || null);
  },
  create(data) {
    return axiosClient.post('/qr-boxes', data);
  },
  update(id, data) {
    return axiosClient.patch(`/qr-boxes/${id}`, data);
  },
  delete(ids) {
    return axiosClient.delete(`/qr-boxes`, { data: { ids } });
  },
};

export const useQrBoxes = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['qr-boxes', 'list', params],
    queryFn: () => qrBoxApi.getAll(params),
    ...options,
  });
};

export const useQrBox = ({ id, params, options } = {}) => {
  return useQuery({
    queryKey: ['qr-boxes', 'details', id, params],
    queryFn: () => qrBoxApi.getById(id, params),
    ...options,
  });
};

export const useCreateQrBox = () => {
  return useMutation(qrBoxApi.create);
};

export const useUpdateQrBox = (id) => {
  return useMutation((data) => qrBoxApi.update(id, data));
};

export const useDeleteQrBox = () => {
  return useMutation({
    mutationFn: (ids) => qrBoxApi.delete(ids),
  });
};
