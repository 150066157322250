import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import flatten from 'flat';
import moment from 'moment';
import { Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useSelector } from 'react-redux';
import 'moment/locale/vi';

import THEME from 'config/themes';
import AppLocale from 'config/translations';
import { validateMessages } from 'constants/common';
import { ROLE } from 'constants/roles';
import { PATH_NAME, PATH_NAME_ACTION } from 'constants/routes';
import GlobalStyle from 'styles/globalStyle';
import { PrivateRoute } from 'components';
import DashboardLayout from './layout/DashboardLayout';
import AuthLayout from './layout/AuthLayout';
import Login from './views/auth/Login';
import Users from './views/users/Users';
import { NotFound } from './components/layout/NotFound';
import { Permission } from 'components/permission/Permission';
import RequestPermissionExport from './views/exports/RequestPermissionExport';
import PhoneCards from './views/phone-cards/PhoneCards';
import Locales from './views/locales/Locales';
import Categories from './views/categories/Categories';
import AddCategory from './views/categories/AddCategory';
import ViewCategory from './views/categories/ViewCategory';
import ViewReviewDashboard from './views/dashboard/ViewReviewDashboard';
import Products from './views/products/Products';
import AddProduct from './views/products/AddProduct';
import ViewProduct from './views/products/ViewProduct';
import QRBoxes from './views/qr-boxes/QRBoxes';
import ViewQRBox from './views/qr-boxes/ViewQRBox';
import AddQRBox from './views/qr-boxes/AddQRBox';
import Answers from './views/answers/Answers';
import Footers from './views/footers/Footers';
import AddAnswer from './views/answers/AddAnswer';
import ViewAnswer from './views/answers/ViewAnswer';
import Questions from './views/questions/Questions';
import AddQuestion from './views/questions/AddQuestion';
import ViewQuestion from './views/questions/ViewQuestion';
import Order from './views/orders/Order';
import ViewOrder from './views/orders/ViewOrder';
// import AddOrder from './views/orders/AddOrder';
import ViewStatistics from './views/dashboard/ViewStatistics';
import AddFooter from './views/footers/AddFooter';
import ViewFooter from './views/footers/ViewFooter';
import AddUser from './views/users/AddUser';
import ViewUser from './views/users/ViewUser';
import AddLocale from './views/locales/AddLocale';
import ViewLocale from './views/locales/ViewLocale';
import Branches from './views/branches/Branches';
import AddBranch from './views/branches/AddBranch';
import ViewBranch from './views/branches/ViewBranch';
import Settings from './views/settings/Settings';
import ViewSetting from './views/settings/ViewSetting';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  const locale = useSelector((state) => state.app.currentLocale);

  moment.locale(locale);

  return (
    <ConfigProvider
      locale={AppLocale[locale].antd}
      form={{ validateMessages: validateMessages[locale], requiredMark: false }}
      // componentSize="small"
    >
      <IntlProvider
        locale={locale}
        messages={flatten(AppLocale[locale].messages)}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={THEME}>
            <GlobalStyle />

            <Routes>
              <Route element={<PrivateRoute />}>
                <Route path={PATH_NAME.ROOT} element={<DashboardLayout />}>
                  <Route
                    index
                    element={<Navigate to={PATH_NAME.DASHBOARD_REVIEW} />}
                  />

                  <Route
                    path={PATH_NAME.DASHBOARD_REVIEW}
                    element={
                      <Permission roles={[ROLE.ADMIN]} noAccess={<NotFound />}>
                        <ViewReviewDashboard />
                      </Permission>
                    }
                  />

                  <Route path={PATH_NAME.USERS}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Users />
                        </Permission>
                      }
                    />

                    <Route
                      path={PATH_NAME_ACTION.CREATE}
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <AddUser />
                        </Permission>
                      }
                    />

                    <Route
                      path=":id"
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <ViewUser />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.BRANCHES}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Branches />
                        </Permission>
                      }
                    />

                    <Route
                      path={PATH_NAME_ACTION.CREATE}
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <AddBranch />
                        </Permission>
                      }
                    />

                    <Route
                      path=":id"
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <ViewBranch />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.CATEGORIES}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Categories />
                        </Permission>
                      }
                    />

                    <Route
                      path={PATH_NAME_ACTION.CREATE}
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <AddCategory />
                        </Permission>
                      }
                    />

                    <Route
                      path=":id"
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <ViewCategory />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.LOCALES}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Locales />
                        </Permission>
                      }
                    />

                    <Route
                      path={PATH_NAME_ACTION.CREATE}
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <AddLocale />
                        </Permission>
                      }
                    />

                    <Route
                      path=":id"
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <ViewLocale />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.QR_BOXES}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <QRBoxes />
                        </Permission>
                      }
                    />

                    <Route
                      path={PATH_NAME_ACTION.CREATE}
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <AddQRBox />
                        </Permission>
                      }
                    />

                    <Route
                      path=":id"
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <ViewQRBox />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.PRODUCTS}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Products />
                        </Permission>
                      }
                    />
                    <Route
                      path={PATH_NAME_ACTION.CREATE}
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <AddProduct />
                        </Permission>
                      }
                    />

                    <Route
                      path=":id"
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <ViewProduct />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.ORDERS}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Order />
                        </Permission>
                      }
                    />
                    <Route
                      path=":id"
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <ViewOrder />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.QUESTIONS}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Questions />
                        </Permission>
                      }
                    />

                    <Route
                      path={PATH_NAME_ACTION.CREATE}
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <AddQuestion />
                        </Permission>
                      }
                    />

                    <Route
                      path=":id"
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <ViewQuestion />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.ANSWERS}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Answers />
                        </Permission>
                      }
                    />

                    <Route
                      path={PATH_NAME_ACTION.CREATE}
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <AddAnswer />
                        </Permission>
                      }
                    />

                    <Route
                      path=":id"
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <ViewAnswer />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.FOOTERS}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Footers />
                        </Permission>
                      }
                    />

                    <Route
                      path={PATH_NAME_ACTION.CREATE}
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <AddFooter />
                        </Permission>
                      }
                    />

                    <Route
                      path=":id"
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <ViewFooter />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route
                    path={PATH_NAME.DASHBOARD_STATISTICS}
                    element={
                      <Permission roles={[ROLE.ADMIN]} noAccess={<NotFound />}>
                        <ViewStatistics />
                      </Permission>
                    }
                  />

                  <Route path={PATH_NAME.SETTINGS}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Settings />
                        </Permission>
                      }
                    />

                    <Route
                      path=":id"
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <ViewSetting />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path="*" element={<NotFound />} />
                </Route>
              </Route>

              <Route element={<AuthLayout />}>
                <Route
                  path={PATH_NAME.REQUEST_PERMISSION_EXPORT}
                  element={<RequestPermissionExport />}
                />

                <Route path={PATH_NAME.LOGIN} element={<Login />} />
              </Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </ThemeProvider>

          <ReactQueryDevtools />
        </QueryClientProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}

export default App;
