import _ from 'lodash';

import { useBranches } from 'api/branchApi';
import { StyledSelect } from 'styles/overrides';
import { filterOptions } from 'utils/formatUtils';

export default function BranchSelector({
  onChange,
  value,
  params = {},
  ...restProps
}) {
  const { data = [], isLoading } = useBranches({
    params: {
      page_size: 1000,
      ...params,
    },
    options: {
      select: (response) =>
        _.map(response?.data, ({ id, name }) => ({
          label: name,
          value: id.toString(),
        })) ?? [],
    },
  });

  return (
    <StyledSelect
      options={data}
      loading={isLoading}
      showSearch
      optionFilterProp="label"
      filterOption={filterOptions}
      {...restProps}
      onChange={onChange}
      value={value}
    />
  );
}
