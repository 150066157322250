import { useQuery } from 'react-query';
import axiosClient from './axiosClient';

export const statisticApi = {
  getRevenue(params) {
    return axiosClient
      .get('/statistical/revenue', { params })
      .then((response) => response?.data || []);
  },
  getOrderQuantity(params) {
    return axiosClient
      .get('/statistical/order', { params })
      .then((response) => response?.data || []);
  },
  getOrderQuantityByLocale(params) {
    return axiosClient
      .get('/statistical/locale', { params })
      .then((response) => response?.data || []);
  },
  getProductQuantity(params) {
    return axiosClient
      .get('/statistical/product-quantity', { params })
      .then((response) => response?.data || []);
  },
  getTotal(params) {
    return axiosClient
      .get('/statistical/total', { params })
      .then((response) => response?.data || null);
  },
};

export const useGetRevenueStatistic = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['statistic', 'revenue', params],
    queryFn: () => statisticApi.getRevenue(params),
    ...options,
  });
};

export const useGetOrdersStatistic = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['statistic', 'orders', params],
    queryFn: () => statisticApi.getOrderQuantity(params),
    ...options,
  });
};

export const useGetOrdersByLocaleStatistic = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['statistic', 'orders-by-locale', params],
    queryFn: () => statisticApi.getOrderQuantityByLocale(params),
    ...options,
  });
};

export const useGetProductsStatistic = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['statistic', 'products', params],
    queryFn: () => statisticApi.getProductQuantity(params),
    ...options,
  });
};

export const useGetTotalStatistic = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['statistic', 'total', params],
    queryFn: () => statisticApi.getTotal(params),
    ...options,
  });
};
