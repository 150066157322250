import React from 'react';
import styled from 'styled-components';

const StyledBox = styled.div``;

const StyledBoxTitle = styled.div`
  color: var(--white);
  background-color: var(--primary);
  text-align: center;
  margin-bottom: 16;
`;

export default function Box({ title, children }) {
  return (
    <StyledBox>{title && <StyledBoxTitle>{title}</StyledBoxTitle>}</StyledBox>
  );
}
