import { useQuery } from 'react-query';
import axiosClient from './axiosClient';

export const dashboardApi = {
  getRevenue(params) {
    return axiosClient
      .get('/dashboard/revenue', { params })
      .then((response) => response?.data || null);
  },
  getOrderQuantity(params) {
    return axiosClient
      .get('/dashboard/order-quantity', { params })
      .then((response) => response?.data || null);
  },
  getOrderQuantityLocale(params) {
    return axiosClient
      .get('/dashboard/order-quantity-locale', { params })
      .then((response) => response?.data || null);
  },
  getTopOrder(params) {
    return axiosClient
      .get('/dashboard/top-order', { params })
      .then((response) => response?.data || []);
  },
  //

  progressChart(params) {
    return axiosClient
      .get('/dashboard/progress-chart', { params })
      .then((response) => response?.data);
  },
  performanceProgress(params) {
    return axiosClient
      .get('/dashboards/implementation-progress', { params })
      .then((response) => response?.data);
  },
  getMapData(params) {
    return axiosClient
      .get('/map/survey-result', { params })
      .then((response) => response?.data);
  },
  getTracking(params) {
    return axiosClient
      .get('/tracking', { params })
      .then((response) => response?.data);
  },
  getMilkGroupChart(params) {
    return axiosClient
      .get('/dashboard/milk-group-chart', { params })
      .then((response) => response?.data);
  },
  getPosmChart(params) {
    return axiosClient
      .get('/dashboard/posm-chart', { params })
      .then((response) => response?.data);
  },
  getBrandChart(params) {
    return axiosClient
      .get('/dashboard/brand-chart', { params })
      .then((response) => response?.data);
  },
  getZoneTypeSizeChart(params) {
    return axiosClient
      .get('/dashboard/zone-type-size-chart', { params })
      .then((response) => response?.data);
  },
};

// TODO:
export const useGetRevenue = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'revenue', params],
    queryFn: () => dashboardApi.getRevenue(params),
    ...options,
  });
};

export const useGetOrderQuantity = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'order-quantity', params],
    queryFn: () => dashboardApi.getOrderQuantity(params),
    ...options,
  });
};

export const useGetOrderQuantityLocale = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'order-quantity-locale', params],
    queryFn: () => dashboardApi.getOrderQuantityLocale(params),
    ...options,
  });
};

export const useGetTopOrder = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'top-order', params],
    queryFn: () => dashboardApi.getTopOrder(params),
    ...options,
  });
};

//
//
export const useProgressChart = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'progress-chart', params],
    queryFn: () => dashboardApi.progressChart(params),
    ...options,
  });
};

export const usePerformanceProgress = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'performance-progress', params],
    queryFn: () => dashboardApi.performanceProgress(params),
    ...options,
  });
};

export const useGetMapData = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'map', params],
    queryFn: () => dashboardApi.getMapData(params),
    ...options,
  });
};

export const useGetTracking = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'tracking', params],
    queryFn: () => dashboardApi.getTracking(params),
    ...options,
  });
};

export const useMilkGroupChart = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'milk-group-chart', params],
    queryFn: () => dashboardApi.getMilkGroupChart(params),
    ...options,
  });
};

export const usePosmChart = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'posm-chart', params],
    queryFn: () => dashboardApi.getPosmChart(params),
    ...options,
  });
};

export const useBrandChart = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'brand-chart', params],
    queryFn: () => dashboardApi.getBrandChart(params),
    ...options,
  });
};

export const useZoneTypeSizeChart = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'zone-type-size-chart', params],
    queryFn: () => dashboardApi.getZoneTypeSizeChart(params),
    ...options,
  });
};
