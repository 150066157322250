import { useIntl } from 'react-intl';
import { Row, Col, Form, Switch } from 'antd';
import {
  StyledForm,
  StyledFormItem,
  StyledInput,
  StyledInputNumber,
  StyledButton,
} from 'styles/overrides';
import {
  AnswerSelector,
  LocaleSelector,
  ProductSelector,
} from 'components/selectors';

export default function QuestionForm({
  onSubmit,
  relatedEntityId,
  localizations,
  initialValues,
  isLoading = false,
}) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    const data = {
      ...values,
      published: +values.published,
      related_id: !!values.related_id ? 'gender' : null,
      inv_question_id: relatedEntityId ?? null,
    };

    onSubmit?.(data);
  };

  const handleChangeLocale = () => {
    form.setFieldsValue({
      answers: undefined,
      products: undefined,
    });
  };

  return (
    <StyledForm
      layout="vertical"
      size="large"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <Row gutter={[32, 0]}>
        <Col span={24} sm={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.answers.form.label.content',
            })}
            name="content"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'common.field.locale',
            })}
            name="locale"
            rules={[{ required: true }]}
          >
            <LocaleSelector
              relatedEntityId={relatedEntityId}
              localizations={localizations}
              onChange={handleChangeLocale}
            />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'common.field.order',
            })}
            name="order"
            rules={[{ required: false }]}
          >
            <StyledInputNumber min={1} />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.questions.form.label.limit',
            })}
            name="limit"
            rules={[{ required: true }]}
          >
            <StyledInputNumber min={1} />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'common.field.published',
            })}
            name="published"
            valuePropName="checked"
          >
            <Switch />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.questions.form.label.related',
            })}
            name="related_id"
            valuePropName="checked"
          >
            <Switch />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.questions.form.label.answers',
            })}
            name="answers"
            rules={[{ required: true }]}
          >
            <AnswerSelector
              mode="multiple"
              params={{ locale_code: initialValues?.locale }}
            />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.questions.form.label.products',
            })}
            name="products"
            rules={[{ required: false }]}
          >
            <ProductSelector
              mode="multiple"
              params={{ locale_code: initialValues?.locale }}
            />
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem style={{ textAlign: 'center' }}>
            <StyledButton htmlType="submit" type="primary" loading={isLoading}>
              {intl.formatMessage({ id: 'common.btn.save' })}
            </StyledButton>
          </StyledFormItem>
        </Col>
      </Row>
    </StyledForm>
  );
}
