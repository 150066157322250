import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const answerApi = {
  getAll(params) {
    return axiosClient.get('/answers', { params });
  },
  getById(id, params) {
    return axiosClient
      .get(`/answers/${id}`, { params })
      .then((response) => response?.data || null);
  },
  create(data) {
    return axiosClient.post('/answers', data);
  },
  update(id, data) {
    return axiosClient.patch(`/answers/${id}`, data);
  },
  delete(ids) {
    return axiosClient.delete('/answers', { data: { ids } });
  },
  getNonLocalizedFields(id) {
    return axiosClient
      .get(`/answers/get-non-localized/${id}`)
      .then((response) => response?.data || []);
  },
};

export const useAnswers = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['answers', 'list', params],
    queryFn: () => answerApi.getAll(params),
    ...options,
  });
};

export const useAnswer = ({ id, params, options } = {}) => {
  return useQuery({
    queryKey: ['answers', 'details', id, params],
    queryFn: () => answerApi.getById(id, params),
    ...options,
  });
};

export const useCreateAnswer = () => {
  return useMutation(answerApi.create);
};

export const useUpdateAnswer = (id) => {
  return useMutation((data) => answerApi.update(id, data));
};

export const useDeleteAnswers = () => {
  return useMutation(answerApi.delete);
};

export const useGetNonLocalizedAnswerFields = ({ id, options }) => {
  return useQuery({
    queryKey: ['answers', 'non-localized-fields', id],
    queryFn: () => answerApi.getNonLocalizedFields(id),
    ...options,
  });
};
