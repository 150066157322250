import styled from 'styled-components';
import { Typography } from 'antd';
import { StyledSpace, StyledAvatar } from 'styles/overrides';

const StyledUserInfo = styled.div`
  .ant-typography {
    color: ${({ isVertical }) =>
      isVertical ? 'var(--white)' : 'var(--gray10)'};
    margin-bottom: 0;
    font-size: 16px;
  }
`;

export const UserInfo = ({ isVertical = false, isCollapsed = false }) => {
  return (
    <StyledUserInfo isVertical={isVertical}>
      <StyledSpace
        direction={isVertical ? 'vertical' : 'horizontal'}
        align="center"
      >
        <StyledAvatar size={40}>U</StyledAvatar>

        {isVertical && (
          <Typography.Text>{process.env.REACT_APP_VERSION}</Typography.Text>
        )}
      </StyledSpace>
    </StyledUserInfo>
  );
};
