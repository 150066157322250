import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const labelApi = {
  getAll(params) {
    return axiosClient
      .get('/labels', { params })
      .then((response) => response?.data || []);
  },
  getByKey(key, params) {
    return axiosClient
      .get(`/labels/${key}`, { params })
      .then((response) => response?.data || null);
  },
  update(key, data) {
    return axiosClient.patch(`/labels/${key}`, data);
  },
};

export const useLabels = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['labels', 'list', params],
    queryFn: () => labelApi.getAll(params),
    ...options,
  });
};

export const useLabel = ({ id, params, options } = {}) => {
  return useQuery({
    queryKey: ['labels', 'details', id, params],
    queryFn: () => labelApi.getByKey(id, params),
    ...options,
  });
};

export const useUpdateLabel = (key) => {
  return useMutation((data) => labelApi.update(key, data));
};
