import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { DEFAULT_PAGINATION } from 'constants/common';
import { FilterForm } from 'components/form';
import { StyledSpace, StyledFormItem } from 'styles/overrides';
import { normalizeSearchData, normalizeSubmitSearchData } from 'utils/common';
import LocalesSelector from 'components/selectors/Locales';

export default function FooterFilterSearch({ onSubmit, initialValues }) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    onSubmit(
      normalizeSubmitSearchData({
        ...initialValues,
        ...values,
        page: DEFAULT_PAGINATION.PAGE,
      }),
    );
  };

  const handleValuesChange = (changedValues) => {};

  return (
    <FilterForm
      onFinish={handleSubmit}
      initialValues={normalizeSearchData(initialValues)}
      formInstance={form}
      onValuesChange={handleValuesChange}
    >
      <StyledSpace size={16}>
        <StyledFormItem name="locale_code">
          <LocalesSelector
            placeholder={intl.formatMessage({
              id: 'common.placeholder.locales',
            })}
            $customType="filter"
          />
        </StyledFormItem>
      </StyledSpace>
    </FilterForm>
  );
}
