import { useIntl } from 'react-intl';
import { Row, Col, Form, Switch } from 'antd';
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledInput,
  StyledInputNumber,
} from 'styles/overrides';
import { MultipleUpload } from 'components/upload';
import { serializeFiles } from 'utils/common';

function normalizeInitialValues(data) {
  if (!data) {
    return { published: true };
  }

  const { name, code, order, file, published } = data;

  return {
    name,
    code,
    order,
    file: [file],
    published: !!published,
  };
}

export default function LocaleForm({
  onSubmit,
  initialValues,
  isLoading = false,
}) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    const data = {
      ...values,
      published: +values.published,
      file: serializeFiles(values.file),
    };

    onSubmit?.(data);
  };

  return (
    <StyledForm
      layout="vertical"
      size="large"
      form={form}
      onFinish={handleSubmit}
      initialValues={normalizeInitialValues(initialValues)}
    >
      <Row gutter={[32, 0]}>
        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.locales.form.label.code',
            })}
            name="code"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.locales.form.label.name',
            })}
            name="name"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.categories.form.label.file',
            })}
            name="file"
            rules={[{ required: true }]}
          >
            <MultipleUpload />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'common.field.order',
            })}
            name="order"
            rules={[{ required: false }]}
          >
            <StyledInputNumber min={1} />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'common.field.published',
            })}
            name="published"
            valuePropName="checked"
          >
            <Switch />
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem style={{ textAlign: 'center' }}>
            <StyledButton htmlType="submit" type="primary" loading={isLoading}>
              {intl.formatMessage({ id: 'common.btn.save' })}
            </StyledButton>
          </StyledFormItem>
        </Col>
      </Row>
    </StyledForm>
  );
}
