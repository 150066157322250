import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { useGetProductsStatistic } from 'api/statisticApi';
import ColumnChart from '../../ColumnChart';

export default function StatisticProductQuantity() {
  const [search] = useCustomSearchParams();
  const { data, isLoading } = useGetProductsStatistic({ params: search });

  if (isLoading) {
    return null;
  }

  return <ColumnChart title="Số lượng Order theo dịch vụ" data={data} />;
}
