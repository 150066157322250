import { useParams } from 'react-router-dom';
import { Notification } from 'components/Notification';
import { ContentWrapper } from 'components/layout';
import { useQuestion, useUpdateQuestion } from 'api/questionApi';
import { QuestionForm } from './components';
import { useIntl } from 'react-intl';
import { Loading } from 'components/loading';
import _ from 'lodash';

function normalizeData(data) {
  if (!data) {
    return null;
  }

  const {
    content,
    locale,
    order,
    answers,
    products,
    published,
    limit,
    related_id,
  } = data;

  return {
    content,
    order,
    limit,
    answers: _.map(answers, (item) => item.id?.toString()),
    products: _.map(products, (item) => item.id?.toString()),
    locale: locale?.code,
    published: !!published,
    related_id: related_id === 'gender',
  };
}

export default function ViewQuestion() {
  const intl = useIntl();
  const { id } = useParams();
  const { data, isLoading, isFetching } = useQuestion({
    id,
  });
  const updateMutation = useUpdateQuestion(id);

  const handleSubmit = (values) => {
    updateMutation.mutate(values, {
      onSuccess() {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.updateSuccess`,
          }),
        );
      },
      onError(error) {
        Notification(
          'error',
          error.message ?? intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  if (isLoading || isFetching) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  return (
    <ContentWrapper title="Chỉnh sửa">
      <QuestionForm
        onSubmit={handleSubmit}
        isLoading={updateMutation.isLoading}
        initialValues={normalizeData(data)}
        relatedEntityId={data.id}
        localizations={data.localizations}
      />
    </ContentWrapper>
  );
}
