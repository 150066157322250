import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Grid, Drawer } from 'antd';
import { ROLE } from 'constants/roles';
import { PATH_NAME } from 'constants/routes';
import {
  StyledLayout,
  StyledLayoutContentWrapper,
  StyledLayoutContent,
} from 'styles/overrides';
import { PeopleIcon, PieChartIcon, InboxIcon } from 'assets/icons';
import {
  ApartmentOutlined,
  GlobalOutlined,
  InsertRowBelowOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  InfoCircleOutlined,
  HomeOutlined,
  BarChartOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { getCurrentUser } from 'store/slices/authSlice';
import { Sider } from './components/Sider';
import { Header } from './components/Header';
import { useLocales } from '../api/localeApi';
import { Loading } from '../components/loading';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { doChangeLocaleCode } from '../store/slices/appSlice';

const MENU_ITEMS_BY_ROLE = {
  [ROLE.ADMIN]: [
    {
      url: PATH_NAME.DASHBOARD_REVIEW,
      key: 'review',
      icon: <BarChartOutlined />,
    },
    {
      url: PATH_NAME.USERS,
      key: 'users',
      icon: <PeopleIcon />,
    },
    {
      url: PATH_NAME.BRANCHES,
      key: 'branches',
      icon: <HomeOutlined />,
    },
    {
      url: PATH_NAME.CATEGORIES,
      key: 'categories',
      icon: <ApartmentOutlined />,
    },

    {
      url: PATH_NAME.PRODUCTS,
      key: 'products',
      icon: <InboxIcon />,
    },
    {
      url: PATH_NAME.ORDERS,
      key: 'orders',
      icon: <InsertRowBelowOutlined />,
    },
    {
      url: PATH_NAME.QUESTIONS,
      key: 'questions',
      icon: <QuestionCircleOutlined />,
    },
    {
      url: PATH_NAME.ANSWERS,
      key: 'answers',
      icon: <ReadOutlined />,
    },
    {
      url: PATH_NAME.LOCALES,
      key: 'locales',
      icon: <GlobalOutlined />,
    },
    {
      url: PATH_NAME.QR_BOXES,
      key: 'qr-boxes',
      icon: <PeopleIcon />,
    },
    {
      url: PATH_NAME.FOOTERS,
      key: 'footers',
      icon: <InfoCircleOutlined />,
    },
    {
      url: PATH_NAME.DASHBOARD_STATISTICS,
      key: 'statistics',
      icon: <PieChartIcon />,
    },
    {
      url: PATH_NAME.SETTINGS,
      key: 'settings',
      icon: <SettingOutlined />,
    },
  ],
  [ROLE.PL]: [],
  [ROLE.SUP]: [],
  [ROLE.QC]: [],
  [ROLE.CUSTOMER]: [],
};

export default function DashboardLayout() {
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const screens = Grid.useBreakpoint();
  const { isLoading } = useLocales({
    options: {
      onSuccess(response) {
        const defaultLocaleItem = _.find(response, (item) => !!item.is_default);

        dispatch(doChangeLocaleCode(defaultLocaleItem?.code));
      },
    },
  });

  const filteredMenuItems = useMemo(
    () => MENU_ITEMS_BY_ROLE[currentUser?.role?.name] ?? [],
    [currentUser],
  );

  const handleToggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <StyledLayout>
        {screens.lg && (
          <Sider collapsed={isCollapsed} items={filteredMenuItems} />
        )}

        <StyledLayoutContentWrapper collapsed={isCollapsed.toString()}>
          <Header onCollapse={handleToggleCollapse} />
          <StyledLayoutContent>
            <Outlet />
          </StyledLayoutContent>
        </StyledLayoutContentWrapper>
      </StyledLayout>

      {!screens.lg && (
        <Drawer
          open={isCollapsed}
          closable={false}
          onClose={() => setIsCollapsed(false)}
          bodyStyle={{ padding: 0 }}
          contentWrapperStyle={{ width: 'auto' }}
          placement="left"
        >
          <Sider items={filteredMenuItems} />
        </Drawer>
      )}
    </>
  );
}
