import { Notification } from 'components/Notification';
import { ContentWrapper } from 'components/layout';
import {
  useCreateCategory,
  useGetNonLocalizedCategoryFields,
} from 'api/categoryApi';
import { CategoryForm } from './components';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { PATH_NAME } from '../../constants/routes';

export default function AddCategory() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [search] = useCustomSearchParams();
  const createMutation = useCreateCategory();
  const { relatedEntityId, ...defaultInitialValues } = useMemo(() => {
    return {
      locale: _.get(search, ['locale_code']),
      relatedEntityId: _.get(search, ['related_entity_id']),
      published: true,
    };
  }, [search]);
  const { data: localizations, isLoading: isLoadingLocalizations } =
    useGetNonLocalizedCategoryFields({
      id: relatedEntityId,
      options: {
        enabled: !!relatedEntityId,
      },
    });

  const handleSubmit = (values) => {
    createMutation.mutate(values, {
      onSuccess(response) {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.createSuccess`,
          }),
        );

        navigate(`/${PATH_NAME.CATEGORIES}/${response.id}`);
      },
      onError(error) {
        Notification(
          'error',
          error.message ?? intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  if (isLoadingLocalizations) {
    return null;
  }

  return (
    <ContentWrapper title="Tạo mới">
      <CategoryForm
        onSubmit={handleSubmit}
        isLoading={createMutation.isLoading}
        relatedEntityId={relatedEntityId}
        initialValues={defaultInitialValues}
        localizations={localizations}
      />
    </ContentWrapper>
  );
}
