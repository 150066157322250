import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const categoryApi = {
  getAll(params) {
    return axiosClient.get('/categories', { params });
  },
  getById(id, params) {
    return axiosClient
      .get(`/categories/${id}`, { params })
      .then((response) => response?.data || null);
  },
  create(data) {
    return axiosClient.post('/categories', data);
  },
  update(id, data) {
    return axiosClient.patch(`/categories/${id}`, data);
  },
  delete(ids) {
    return axiosClient.delete('/categories', { data: { ids } });
  },
  getNonLocalizedFields(id) {
    return axiosClient
      .get(`/categories/get-non-localized/${id}`)
      .then((response) => response?.data || []);
  },
};

export const useCategories = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['categories', 'list', params],
    queryFn: () => categoryApi.getAll(params),
    ...options,
  });
};

export const useCategory = ({ id, params, options } = {}) => {
  return useQuery({
    queryKey: ['categories', 'details', id, params],
    queryFn: () => categoryApi.getById(id, params),
    ...options,
  });
};

export const useCreateCategory = () => {
  return useMutation(categoryApi.create);
};

export const useUpdateCategory = (id) => {
  return useMutation((data) => categoryApi.update(id, data));
};

export const useDeleteCategories = () => {
  return useMutation(categoryApi.delete);
};

export const useGetNonLocalizedCategoryFields = ({ id, options }) => {
  return useQuery({
    queryKey: ['categories', 'non-localized-fields', id],
    queryFn: () => categoryApi.getNonLocalizedFields(id),
    ...options,
  });
};
