export const COLOR_INFO = '#3498db';
export const COLOR_SUCCESS = '#2ecc71';
export const COLOR_WARNING = '#f1c40f';
export const COLOR_ERROR = '#e74c3c';
export const COLOR_STORE_SUCCESS = '#10EF77';
export const COLOR_STORE_UNSUCCESS = '#AC2900';
export const COLOR_STORE_REFUSE = '#963634';
export const COLOR_STORE_CLOSE = '#FF9900';
export const COLOR_STORE_ABSENT = '#E26B0A';
export const COLOR_STORE_DID_SURVEY = '#20DF9D';
export const COLOR_STORE_DOING_SURVEY = '#20C1DF';
