import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const userApi = {
  getAll(params) {
    return axiosClient.get('/users', { params });
  },
  getById(id, params) {
    return axiosClient
      .get(`/users/${id}`, { params })
      .then((response) => response?.data || null);
  },
  create(data) {
    return axiosClient.post('/users', data);
  },
  update(id, data) {
    return axiosClient.patch(`/users/${id}`, data);
  },
  delete(ids) {
    return axiosClient.delete('/users', { data: { ids } });
  },
  getRoles(params) {
    return axiosClient
      .get('/users/roles', { params })
      .then((response) => response?.data ?? []);
  },
};

export const useUsers = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['users', 'list', params],
    queryFn: () => userApi.getAll(params),
    ...options,
  });
};

export const useUser = ({ id, params, options } = {}) => {
  return useQuery({
    queryKey: ['users', 'details', id, params],
    queryFn: () => userApi.getById(id, params),
    ...options,
  });
};

export const useUserRoles = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['users', 'roles', params],
    queryFn: () => userApi.getRoles(params),
    ...options,
  });
};

export const useCreateUser = () => {
  return useMutation(userApi.create);
};

export const useUpdateUser = (id) => {
  return useMutation((data) => userApi.update(id, data));
};

export const useDeleteUser = () => {
  return useMutation(userApi.delete);
};
