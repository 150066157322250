import { useMemo } from 'react';
import { Column } from '@ant-design/plots';
import _ from 'lodash';
import { Typography } from 'antd';
import BoxWrapper from '../BoxWrapper';
import { formatInputNumber } from 'utils/formatUtils';

export default function ColumnChart({ title, data = [] }) {
  console.log({ data });
  const config = useMemo(() => {
    return {
      data,
      xField: 'name',
      yField: 'value',
      // seriesField: '',
      // color: ({ name }) => {
      //   if (name === 'today') {
      //     return '#F4664A';
      //   }

      //   return '#5B8FF9';
      // },
      // seriesField: 'name',
      // color: _.map(data, (item) => item.color),
      xAxis: {
        label: {
          autoHide: false,
          autoRotate: true,
          style: { fontSize: 10 },
        },
      },
      yAxis: {
        label: {
          formatter: (text) => formatInputNumber(text),
        },
      },
      minColumnWidth: 40,
      maxColumnWidth: 40,
      label: {
        position: 'middle',
        formatter: (item) => {
          if (!+item.value) {
            return null;
          }

          return formatInputNumber(item.value);
        },
        style: {
          fill: '#000',
        },
      },
      tooltip: {
        customItems: (originalItems) => {
          return originalItems.map((item) => ({
            ...item,
            value: formatInputNumber(item.value),
            name: 'Tổng',
          }));
        },
      },
    };
  }, [data]);

  return (
    <BoxWrapper>
      <Typography.Title level={3} style={{ textAlign: 'center' }}>
        {title}
      </Typography.Title>

      <Column {...config} />
    </BoxWrapper>
  );
}
