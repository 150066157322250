import { useIntl } from 'react-intl';
import { Row, Col, Switch, Form } from 'antd';
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledInput,
  StyledInputNumber,
} from 'styles/overrides';
import { MultipleUpload } from 'components/upload';
import { serializeFiles } from 'utils/common';

const serializeSubmitData = (values) => {
  const data = {
    ...values,
    published: +values.published,
    file: serializeFiles(values.file),
  };

  return data;
};

const normalizeData = (values) => {
  if (!values) {
    return null;
  }

  const { file, name, order, published, link } = values;

  return {
    name,
    order,
    link,
    file: [file],
    published: !!published,
  };
};

export default function QRForm({ initialValues, onSubmit, isLoading = false }) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    const data = serializeSubmitData(values);

    onSubmit?.(data);
  };

  return (
    <StyledForm
      layout="vertical"
      size="large"
      form={form}
      initialValues={normalizeData(initialValues) ?? { published: true }}
      onFinish={handleSubmit}
    >
      <Row gutter={[32, 0]}>
        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.qrBoxes.form.label.name',
            })}
            name="name"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.qrBoxes.form.label.link',
            })}
            name="link"
            rules={[{ required: false }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.qrBoxes.form.label.file',
            })}
            name="file"
            rules={[{ required: true }]}
          >
            <MultipleUpload />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={6}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'common.field.order',
            })}
            name="order"
            rules={[{ required: false }]}
          >
            <StyledInputNumber min={1} />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={6}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'common.field.published',
            })}
            name="published"
            valuePropName="checked"
          >
            <Switch />
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem style={{ textAlign: 'center' }}>
            <StyledButton htmlType="submit" type="primary" loading={isLoading}>
              {intl.formatMessage({ id: 'common.btn.save' })}
            </StyledButton>
          </StyledFormItem>
        </Col>
      </Row>
    </StyledForm>
  );
}
