import { useIntl } from 'react-intl';
import { Row, Col, Form } from 'antd';
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledInput,
} from 'styles/overrides';
import {
  DistrictSelector,
  ProvinceSelector,
  WardSelector,
} from 'components/selectors';

function normalizeInitialValues(data) {
  if (!data) {
    return null;
  }

  const { name, code, address, province, district, ward } = data;

  return {
    name,
    code,
    address,
    province_id: province?.id?.toString(),
    district_id: district?.id?.toString(),
    ward_id: ward?.id?.toString(),
  };
}

export default function BranchForm({
  onSubmit,
  initialValues,
  isLoading = false,
}) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    onSubmit?.(values);
  };

  const handleValuesChange = (changedValues) => {
    if (changedValues['province_id']) {
      form.setFieldsValue({
        district_id: null,
        ward_id: null,
      });
    }

    if (changedValues['district_id']) {
      form.setFieldsValue({
        ward_id: null,
      });
    }
  };

  return (
    <StyledForm
      layout="vertical"
      size="large"
      form={form}
      onFinish={handleSubmit}
      initialValues={normalizeInitialValues(initialValues)}
      onValuesChange={handleValuesChange}
    >
      <Row gutter={[32, 0]}>
        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.branches.form.label.name',
            })}
            name="name"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.branches.form.label.code',
            })}
            name="code"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.branches.form.label.address',
            })}
            name="address"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.branches.form.label.provinceId',
            })}
            name="province_id"
            rules={[{ required: true }]}
          >
            <ProvinceSelector />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.province_id !== currentValues.province_id
            }
          >
            {({ getFieldValue }) => {
              const provinceId = getFieldValue('province_id');

              return (
                <StyledFormItem
                  label={intl.formatMessage({
                    id: 'views.branches.form.label.districtId',
                  })}
                  name="district_id"
                  rules={[{ required: true }]}
                >
                  <DistrictSelector provinceId={provinceId} />
                </StyledFormItem>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={24} sm={12}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.province_id !== currentValues.province_id ||
              prevValues.district_id !== currentValues.district_id
            }
          >
            {({ getFieldValue }) => {
              const provinceId = getFieldValue('province_id');
              const districtId = getFieldValue('district_id');

              return (
                <StyledFormItem
                  label={intl.formatMessage({
                    id: 'views.branches.form.label.wardId',
                  })}
                  name="ward_id"
                  rules={[{ required: true }]}
                >
                  <WardSelector
                    provinceId={provinceId}
                    districtId={districtId}
                  />
                </StyledFormItem>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={24}>
          <StyledFormItem style={{ textAlign: 'center' }}>
            <StyledButton htmlType="submit" type="primary" loading={isLoading}>
              {intl.formatMessage({ id: 'common.btn.save' })}
            </StyledButton>
          </StyledFormItem>
        </Col>
      </Row>
    </StyledForm>
  );
}
