import { useParams } from 'react-router-dom';
import { Notification } from 'components/Notification';
import { ContentWrapper } from 'components/layout';
import { SettingForm } from './components';
import { useIntl } from 'react-intl';
import { Loading } from 'components/loading';
import { useLabel, useUpdateLabel } from '../../api/labelApi';

function normalizeData(data) {
  if (!data) {
    return null;
  }

  const { value } = data;

  return { value };
}

export default function ViewSetting() {
  const intl = useIntl();
  const { id } = useParams();
  const { data, isLoading, isFetching } = useLabel({
    id,
  });
  const updateMutation = useUpdateLabel(id);

  const handleSubmit = (values) => {
    updateMutation.mutate(values, {
      onSuccess() {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.updateSuccess`,
          }),
        );
      },
      onError(error) {
        Notification(
          'error',
          error.message ?? intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  if (isLoading || isFetching) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  return (
    <ContentWrapper title="Chỉnh sửa">
      <SettingForm
        onSubmit={handleSubmit}
        isLoading={updateMutation.isLoading}
        initialValues={normalizeData(data)}
        isGender={data.key === 'gender'}
      />
    </ContentWrapper>
  );
}
