import { useParams } from 'react-router-dom';
import { Notification } from 'components/Notification';
import { ContentWrapper } from 'components/layout';
import { useProduct, useUpdateProduct } from 'api/productApi';
import ProductForm from './components/ProductForm';
import { useIntl } from 'react-intl';

function normalizeData(data) {
  const {
    file,
    files,
    description,
    name,
    locale,
    price,
    percent_discount,
    published,
    time,
    category,
    introduce,
  } = data;

  return {
    name,
    description,
    price,
    percent_discount,
    time,
    locale: locale?.code,
    file: [file],
    files: files,
    published: !!published,
    category_id: category?.id?.toString(),
    introduce,
  };
}

export default function ViewProduct() {
  const intl = useIntl();
  const { id } = useParams();
  const { data, isLoading, isFetching } = useProduct({
    id,
  });
  const updateMutation = useUpdateProduct(id);

  const handleSubmit = (values) => {
    updateMutation.mutate(values, {
      onSuccess() {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.updateSuccess`,
          }),
        );
      },
      onError(error) {
        Notification(
          'error',
          error.message ?? intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  if (isLoading || !data || isFetching) {
    return null;
  }

  return (
    <ContentWrapper title="Chỉnh sửa">
      <ProductForm
        onSubmit={handleSubmit}
        isLoading={updateMutation.isLoading}
        initialValues={normalizeData(data)}
        relatedEntityId={data.id}
        localizations={data.localizations}
        localeCode={data?.locale?.code}
      />
    </ContentWrapper>
  );
}
