import { Notification } from 'components/Notification';
import { ContentWrapper } from 'components/layout';
import { useCreateUser } from 'api/userApi';
import { UserForm } from './components';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { PATH_NAME } from 'constants/routes';

export default function AddUser() {
  const intl = useIntl();
  const navigate = useNavigate();
  const createMutation = useCreateUser();

  const handleSubmit = (values) => {
    createMutation.mutate(values, {
      onSuccess(response) {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.createSuccess`,
          }),
        );

        navigate(`/${PATH_NAME.USERS}/${response.id}`);
      },
      onError(error) {
        Notification(
          'error',
          error.message ?? intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  return (
    <ContentWrapper title="Tạo mới">
      <UserForm onSubmit={handleSubmit} isLoading={createMutation.isLoading} />
    </ContentWrapper>
  );
}
