import { useIntl } from 'react-intl';
import { Row, Col, Form, Switch } from 'antd';
import {
  StyledForm,
  StyledFormItem,
  StyledInput,
  StyledInputNumber,
  StyledButton,
  StyledInputTextArea,
} from 'styles/overrides';
import { MultipleUpload } from 'components/upload';
import { EditorField } from 'components/ui';
import { CategorySelector, LocaleSelector } from 'components/selectors';
import { serializeFiles } from 'utils/common';

export default function ProductForm({
  localeCode,
  relatedEntityId,
  localizations,
  onSubmit,
  initialValues,
  isLoading = false,
}) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    const data = {
      ...values,
      published: +values.published,
      file: serializeFiles(values.file),
      files: serializeFiles(values.files, 'multiple'),
      inv_product_id: relatedEntityId ?? null,
    };

    onSubmit?.(data);
  };

  const handleChangeLocale = () => {
    form.setFieldsValue({
      category_id: undefined,
    });
  };

  return (
    <StyledForm
      layout="vertical"
      size="large"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues ?? { published: true }}
    >
      <Row gutter={[32, 0]}>
        <Col span={24} sm={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.products.form.label.name',
            })}
            name="name"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={12} sm={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.products.form.label.categoryId',
            })}
            name="category_id"
            rules={[{ required: true }]}
          >
            <CategorySelector allowClear params={{ locale_code: localeCode }} />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'common.field.locale',
            })}
            name="locale"
            rules={[{ required: true }]}
          >
            <LocaleSelector
              relatedEntityId={relatedEntityId}
              localizations={localizations}
              onChange={handleChangeLocale}
            />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.products.form.label.price',
            })}
            name="price"
            rules={[{ required: true }]}
          >
            <StyledInputNumber min={0} controls={false} />
          </StyledFormItem>
        </Col>

        <Col span={12} sm={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.products.form.label.percentDiscount',
            })}
            name="percent_discount"
            rules={[{ required: false }]}
          >
            <StyledInputNumber min={0} max={100} controls={false} />
          </StyledFormItem>
        </Col>

        <Col span={12} sm={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.products.form.label.time',
            })}
            name="time"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.products.form.label.introduce',
            })}
            name="introduce"
            rules={[{ required: true }]}
          >
            <StyledInputTextArea rows={3} />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.products.form.label.description',
            })}
            name="description"
            rules={[{ required: true }]}
          >
            <EditorField />
          </StyledFormItem>
        </Col>

        <Col span={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.products.form.label.avatar',
            })}
            name="file"
            rules={[{ required: true }]}
          >
            <MultipleUpload />
          </StyledFormItem>
        </Col>

        <Col span={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.products.form.label.images',
            })}
            name="files"
            rules={[{ required: false }]}
          >
            <MultipleUpload fileMax={100} />
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'common.field.published',
            })}
            name="published"
            valuePropName="checked"
          >
            <Switch />
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem style={{ textAlign: 'center' }}>
            <StyledButton htmlType="submit" type="primary" loading={isLoading}>
              {intl.formatMessage({ id: 'common.btn.save' })}
            </StyledButton>
          </StyledFormItem>
        </Col>
      </Row>
    </StyledForm>
  );
}
