import { useTechnicians } from 'api/publicApi';
import { StyledSelect } from 'styles/overrides';
import _ from 'lodash';
import { filterOptions } from 'utils/formatUtils';

export default function TechnicianSelector({ onChange, value, ...restProps }) {
  const { data = [], isLoading } = useTechnicians({
    options: {
      select: (response) =>
        _.map(response, ({ code, full_name }) => ({
          label: `${full_name} - ${code}`,
          value: code,
        })) ?? [],
    },
  });

  return (
    <StyledSelect
      options={data}
      loading={isLoading}
      showSearch
      optionFilterProp="name"
      filterOption={filterOptions}
      {...restProps}
      onChange={onChange}
      value={value}
    />
  );
}
