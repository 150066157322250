import GroupedColumnChart from '../GroupedColumnChart';
import { useGetOrderQuantityLocale } from 'api/dashboardApi';
import _ from 'lodash';

function normalizeData(data) {
  return _.reduce(
    data,
    (acc, value, key) => {
      const mapping = _.map(value, (v, k) => ({
        name: _.capitalize(k),
        xField: _.capitalize(key),
        yField: v,
      }));

      acc.push(...mapping);

      return acc;
    },
    [],
  );
}

export default function OrderQuantityLocaleBlock() {
  const { data, isLoading } = useGetOrderQuantityLocale();

  if (isLoading) {
    return null;
  }

  return (
    <GroupedColumnChart
      data={normalizeData(data)}
      name="Số lượng dịch vụ theo ngôn ngữ đã order"
    />
  );
}
