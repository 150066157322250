import { useIntl } from 'react-intl';
import { Row, Col, Form } from 'antd';
import {
  StyledForm,
  StyledFormItem,
  StyledButton,
  StyledInputTextArea,
} from 'styles/overrides';
import { LocaleSelector } from 'components/selectors';

export default function FooterForm({
  onSubmit,
  initialValues,
  isLoading = false,
}) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    onSubmit?.(values);
  };

  return (
    <StyledForm
      layout="vertical"
      size="large"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <Row gutter={[32, 0]}>
        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.footers.form.label.content',
            })}
            name="content"
            rules={[{ required: true }]}
          >
            <StyledInputTextArea rows={3} />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'common.field.locale',
            })}
            name="locale"
            rules={[{ required: true }]}
          >
            <LocaleSelector isFilter />
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem style={{ textAlign: 'center' }}>
            <StyledButton htmlType="submit" type="primary" loading={isLoading}>
              {intl.formatMessage({ id: 'common.btn.save' })}
            </StyledButton>
          </StyledFormItem>
        </Col>
      </Row>
    </StyledForm>
  );
}
