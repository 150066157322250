import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function EditorField({ value, onChange }) {
  const modules = {
    toolbar: {
      container: [
        [{ color: [] }, { background: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['clean'],
      ],
    },
  };

  const formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'color',
    'background',
  ];

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={(content) => {
        onChange(content);
      }}
      modules={modules}
      formats={formats}
    />
  );
}
