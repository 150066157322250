import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const questionApi = {
  getAll(params) {
    return axiosClient.get('/questions', { params });
  },
  getById(id, params) {
    return axiosClient
      .get(`/questions/${id}`, { params })
      .then((response) => response?.data || null);
  },
  create(data) {
    return axiosClient.post('/questions', data);
  },
  update(id, data) {
    return axiosClient.patch(`/questions/${id}`, data);
  },
  delete(ids) {
    return axiosClient.delete('/questions', { data: { ids } });
  },
  getNonLocalizedFields(id) {
    return axiosClient
      .get(`/questions/get-non-localized/${id}`)
      .then((response) => response?.data || []);
  },
};

export const useQuestions = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['questions', 'list', params],
    queryFn: () => questionApi.getAll(params),
    ...options,
  });
};

export const useQuestion = ({ id, params, options } = {}) => {
  return useQuery({
    queryKey: ['questions', 'details', id, params],
    queryFn: () => questionApi.getById(id, params),
    ...options,
  });
};

export const useCreateQuestion = () => {
  return useMutation(questionApi.create);
};

export const useUpdateQuestion = (id) => {
  return useMutation((data) => questionApi.update(id, data));
};

export const useDeleteQuestions = () => {
  return useMutation(questionApi.delete);
};

export const useGetNonLocalizedQuestionFields = ({ id, options }) => {
  return useQuery({
    queryKey: ['questions', 'non-localized-fields', id],
    queryFn: () => questionApi.getNonLocalizedFields(id),
    ...options,
  });
};
